$alertbox-primary-color: #ffffff;
$alertbox-text-color: #000000;

/*Alert*/
.alertbox {
    width: 34rem;
    max-width: calc(100% - 3.5rem);
    height: auto;
    max-height: 200px;
    min-height: 50px;
    position: fixed;
    bottom: 1.75rem;
    right: 1.75rem;
    margin-bottom: 0;
    z-index: 13;
    font-weight: 400;
    font-size: 1rem;
    @media (max-width: 1199px) {
        font-size: 1.4rem;
        width: 40rem;
        padding-left: 5.5rem;
    }
    box-shadow: 1px 7px 14px -5px rgba(0, 0, 0, 0.2);
    background: $alertbox-primary-color;
    color: $alertbox-text-color;
    border-radius: 4px;
    border-left: 4px solid transparent;
    overflow: hidden;
    padding: 1.4rem 3.5rem 1.4rem 4.5rem;
    transition: all 1s;
    opacity: 0;
    transform: translateY(200%);

    animation: alertbox-in 6s 1 forwards;

    strong {
        display: block;
    }

    p {
        font-size: 1rem;
        margin: 0;
        color: lighten($alertbox-text-color, 30%);
    }

    &.hide {
        animation: none;
        opacity: 0;
        transform: translateY(200%);
    }
}

.alertbox-icon-wrap {
    position: absolute;
    top: 50%;
    left: 1.3rem;
    transform: translateY(-50%);
    width: 2rem;
    height: 2rem;
    padding: 7px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 1199px) {
        width: 3rem;
        height: 3rem;
    }
}

.alertbox-icon {
    width: 1.5rem;
    height: 1.5rem;
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
    @media (max-width: 1199px) {
        width: 2rem;
        height: 2rem;
    }
}

.alertbox-close {
    position: absolute;
    right: 1rem;
    top: 50%;
    width: 1.5rem;
    cursor: pointer;
    height: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateY(-50%);
    background-image: url("../../img/alertbox/close.svg");
    background-size: 70%;
    background-position: center;
    background-repeat: no-repeat;
}

$alertbox-colors: (
    'danger': #f44336,
    'success': #2BDE3F,
    'info': #1D72F3
);

@each $alertbox-box, $color in $alertbox-colors {
    .alertbox {
        &.alertbox-#{$alertbox-box} {
            border-color: $color;

            .alertbox-icon-wrap {
                background: $color;
            }

            .alertbox-icon {
                background-image: url("../../img/alertbox/" + $alertbox-box + ".svg");
            }
        }
    }
}


@keyframes alertbox-in {
    0% {
        opacity: 0;
        transform: translateY(200%);
    }
    20%, 50%, 80% {
        opacity: 1;
        transform: translateY(0);
    }
    100% {
        opacity: 0;
        transform: translateY(200%);
    }
}
