.ourdrivers {
  background-color: $base-color-darken;
  overflow: hidden;
  .section-title {
    margin-bottom: 2.4rem;
    @media (max-width: 767px) {
      margin-bottom: 1.5rem;
    }
    &__title {
      color: $secondary-color;
      text-align: center;
    }
  }
  .section-title__span {
    text-align: center;
  }
  &__type__item {
    margin: 1rem 0 0;
    padding: 1rem 2rem;
    border-radius: 5rem;
    background: #fff;
    font-weight: bold;
  }
  &__type_content {
    margin-right: 0.3rem;
  }
  &__content {
    color: $primary-color;
  }
  &__item {
    height: 19.5rem;
    border-radius: 0.5rem;
    margin-top: 3.05rem;
    background: #fff;
    box-shadow: 0 0.375rem 1.5rem rgba(0, 0, 0, 0.1);
    text-align: center;
    cursor: pointer;
    transition: $transition-time;
    @media (max-width: 767px) {
      margin-top: 2rem;
      height: 15rem;
    }
    &:hover:not(.active) {
      box-shadow: 0 0.375rem 1.5rem rgba(0, 0, 0, 0.3);
    }
    &:not(.active) {
      .ourdrivers__icons__icon {
        display: none
      }
      .ourdrivers__description {
        margin: 0.7rem 0 0;
      }
    }
    &.active {
      background: $secondary-color;
      .ourdrivers__description {
        color: #fff;
      }
      .ourdrivers__title {
        color: #fff;
      }
    }
  }
  &__icons__icon {
    width: 2.688rem;
    height: 2.688rem;
  }
  &__price {
    margin-top: -0.3rem;
    color: $primary-color;
    font-size: 3rem;
    font-weight: bold;
    font-family: $second-font;
    @media (max-width: 991px) {
      font-size: 2rem;
    }
    @media (max-width: 767px) {
      font-size: 1.5rem;
    }
  }
  &__title {
    margin: 0.7rem 0 1.6rem 1.3rem;
  }
  &__button__wrap {
    margin-top: 3.2rem;
    text-align: center;
  }
}