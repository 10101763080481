.intro-info {
  position: relative;
  display: flex;
  align-items: center;
  height: 34.375rem;
  width: 100%;
  background-image: url(../img/info/house.jpg);
  background-position: top, center;
  background-repeat: no-repeat;
  background-size: cover;
  @media (max-width: 767px){
    height:25rem;
  }
  &:before{
    z-index:0;
    content:'';
    position: absolute;
    height:100%;
    width: 100%;
    background: rgba(53, 65, 84,  0.76);
  }
  .intro__title{
    position: relative;
    margin-top: 9.5rem;
    padding: 0 9rem;
    text-align: center;
    @media (max-width: 991px){
      padding: 0;
    }
    @media (max-width: 767px){
      margin-top: 3.5rem;;
    }
  }
}