/*Document page*/
#document {
    margin: 9rem 0 5rem 0;
    @media (max-width: 991px) {
        margin: 6rem 0 2rem 0;
    }
    h1 {
        margin-bottom: 4rem;
    }
    h3 {
        margin-top: 3rem;
        &:first-child {
            margin-top: 0;
        }
    }
    .first-level {
        padding-left: 30px;
    }
    .second-level {
        padding-left: 60px;
    }
    a {
        word-wrap: break-word;
    }
    .highlighted {
        font-style: italic;
        font-size: 1.5rem;
    }
}
