.why {
    overflow: hidden;
    .section-title {
        text-align: center;
    }
    &__wrapper {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin-top: 3rem;
    }
    &__item {
        margin: 1rem 0;
        width: 100%;
        @media (min-width: 992px) {
            width: calc(100% / 2);
        }
        @media (min-width: 1199px) {
            width: calc(100% / 3);
        }
        &__inner {
            height: 100%;
            background-color: rgba($base-color-darken, .55);
            padding: 2rem;
            @media (min-width: 768px) {
                padding: 3rem;
            }
            @media (min-width: 992px) {
                margin: 0 1rem;
            }
        }
    }
    &__icon {
        margin-bottom: 1rem;
        width: 4.5rem;
        height: 4.5rem;
        object-fit: contain;
        @media (max-width: 767px) {
            width: 5rem;
            height: 5rem;
        }
        @media (max-width: 575px) {
            width: 4rem;
            height: 4rem;
        }
    }
    &__title {
        margin-bottom: 1rem;
    }
}