#app {
    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.5s ease;
    }

    .fade-enter-from,
    .fade-leave-to {
        opacity: 0;
    }

    .box {
        max-width: 60rem;
        margin: 0 auto;
        padding: 3rem 0;
    }
    .title {
        margin-bottom: 1rem;
        text-align: center;
    }
    .description {
        text-align: center;
    }
    .box-item {
        width: 100%;
        height: 100%;

        &--date {
            position: relative;
            &::before {
                content: "";
                position: absolute;
                top: 50%;
                left: 1.1rem;
                width: 2.5rem;
                height: 2.5rem;
                background-image: url("../img/icons/calendar.svg");
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;

                transform: translateY(-50%);
                pointer-events: none;
            }
            input {
                padding-left: 4.3rem;
            }
        }

        &--address {
            position: relative;
            &::before {
                content: "";
                position: absolute;
                top: 50%;
                left: 1.1rem;
                width: 2.5rem;
                height: 2.5rem;
                background-image: url("../img/icons/address.svg");
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;

                transform: translateY(-50%);
                pointer-events: none;
            }
            input {
                padding-left: 4.3rem;
            }
        }
    }
    .box-button {
        text-align: center;
        margin-top: 2rem;
    }
    .box-label {
        position: relative;
        width: 100%;
        height: 100%;
        margin-bottom: 0;
        background: #ffffff;
        color: #324155;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
        border-radius: 6px;
        border: 1px solid transparent;
        font-weight: 600;
        padding: 2.5rem 1rem;
        cursor: pointer;

        transition: color 0.3s, border-color 0.3s;

        &:hover {
            border-color: #8dcf3f;
            color: #8dcf3f;

            small {
                color: #99a0aa;
            }
            strong {
                color: #324155;
            }
        }

        small {
            display: block;
            font-weight: 400;
            font-size: 80%;
            color: #99a0aa;
            margin-top: 0.5rem;

            strong {
                font-weight: 600;
                display: block;
                color: #324155;
            }
        }

        strong {
            &.simple {
                margin-top: auto;
                padding-top: 0.5rem;
                color: #324155;
            }
        }

        &--w-icon {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
        }

        &__icon {
            width: 3.5rem;
            height: 3.5rem;
            object-fit: contain;
            margin-bottom: 0.5rem;
        }

        &::before {
            content: "";
            position: absolute;
            top: 1rem;
            left: 50%;
            transform: translateX(-50%);

            width: 1rem;
            height: 1rem;

            background-image: url("../img/icons/check.svg");
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;

            opacity: 0;
            transition: opacity 0.3s;
        }
    }

    .box-radio {
        display: none;

        &:checked {
            & ~ .box-label {
                border-color: #8dcf3f;
                color: #8dcf3f;

                small {
                    color: #99a0aa;
                }
                strong {
                    color: #324155;
                }

                &::before {
                    opacity: 1;
                }
            }
        }
    }

    .box-textarea {
        width: 100%;
        height: 100%;
        margin-bottom: 0;
        background: #ffffff;
        color: #324155;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
        border-radius: 6px;
        border: 1px solid transparent;
        padding: 1.3rem 1.7rem;
        font-size: 1.2rem;

        transition: box-shadow 0.3s;

        &::placeholder {
            color: #324155;
            font-weight: 500;
            font-size: 1rem;
        }

        &:focus {
            border: 1px solid #8dcf3f;
            outline: none;
            box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
        }
    }

    .box-input {
        width: 100%;
        margin-bottom: 0;
        background: #ffffff;
        color: #324155;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
        border-radius: 6px;
        padding: 1.3rem 1.7rem;
        font-size: 1.2rem;

        transition: box-shadow 0.3s;

        &::placeholder {
            color: #324155;
            font-weight: 500;
            font-size: 1rem;
        }

        &:focus {
            outline: none;
            box-shadow: 0px 4px 20px rgba(#8dcf3f, 0.5);
        }

        &.invalid {
            box-shadow: 0px 4px 20px rgba(red, 0.5);
        }

        @media (max-width: 767px) {
            width: 100%;
        }

        &--full {
            width: 100%;
        }
    }

    .box-summary {
        width: 100%;
        margin-bottom: 1rem;
        background: #ffffff;
        color: #324155;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
        border-radius: 6px;
        padding: 1.3rem 1.7rem;

        &__title {
            font-size: 0.8rem;
            font-weight: 600;
            display: block;
        }

        &__value {
            font-size: 1rem;
            font-weight: 500;
            display: block;
        }

        &__text {
            font-size: 0.9rem;
        }

        &--column {
            column-count: 3;
            @media (max-width: 767px) {
                column-count: 2;
            }
            @media (max-width: 575px) {
                column-count: 1;
            }
        }

        &--info {
            background-color: #354154;
            color: #fff;
        }
    }

    .button {
        display: block;
        width: 100%;
        margin: 0 auto;
        padding: 0.8rem 2rem;
        border-radius: 10px;
        border: 1px solid #8dcf3f;
        outline: none;
        font-size: 1.2rem;
        font-weight: 500;
        text-align: center;
        text-transform: uppercase;
        background-color: #8dcf3f;
        color: #ffffff;
        transition: all 0.3s;
        z-index: 5;

        &:disabled {
            opacity: 0.5;
            cursor: not-allowed;
        }

        &:hover {
            background-color: #73af2c;
            border-color: #73af2c;
            color: #ffffff;
            box-shadow: 0px 8px 10px 0px rgb(141 207 63 / 25%);
        }
    }
}
.flatpickr-input {
    -webkit-appearance: none;
}
.flatpickr-monthDropdown-months {
    appearance: none !important;
    background-color: #fff !important;
    border: none !important;
    border-radius: 0 !important;
    padding: 0 !important;
    line-height: 1.4 !important;
    font-size: 0.9em !important;
}
