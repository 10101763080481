.howitworks {
    background-color: $base-color-darken;
    overflow: hidden;
    .section-title__title {
        color: $secondary-color;
        max-width: 52rem;
        span {
            white-space: nowrap;
        }
    }
    &__description {
        max-width: 50rem;
        margin-top: 2rem;
    }
    &__image {
        margin-bottom: -2rem;
        @media (min-width: 1200px) {
            margin-top: -2rem;
        }
        &__img {
            max-width: 100%;
            height: auto;
        }
    }
    &__icons {
        display: flex;
        flex-wrap: wrap;
        margin: 2rem 0;
        &__item {
            width: calc(100% / 3);
            padding-right: 1rem;
            @media (max-width: 767px) {
                width: 100%;
                padding-right: 0;
                margin-bottom: 2rem;
            }
        }
        &__icon {
            margin-bottom: 1rem;
            @media (max-width: 767px) {
                width: 5rem;
                height: 5rem;
                object-fit: contain;
                margin-right: 2rem;
            }
            @media (max-width: 575px) {
                width: 4rem;
                height: 4rem;
            }
        }
    }
}
