.form-home {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin: 2rem 0;
    width: 37rem;
    max-width: 100%;
    @media (max-width: 991px) {
        margin-left: auto;
        margin-right: auto;
    }
    &__form-wrapper {
        background: $tertiary-color;
        border-radius: 8px;
        padding: 1.5rem 1rem 1rem;
    }
    &__input {
        width: 100%;
        padding-right: 8rem;
        &__wrap {
            width: 100%;
            position: relative;
            &::after {
                content: '';
                position: absolute;
                top: 50%;
                left: 1.3rem;
                width: 1.3rem;
                height: 1.3rem;
                transform: translateY(-50%);
                background: url('../img/intro/input-icon.png');
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
            }
        }
    }
    &__button {
        &__wrap {
            position: absolute;
            top: 0;
            right: 0;
            .btn-own {
                padding: 1rem 2rem;
            }
        }
    }
}