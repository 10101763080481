.chooseus {
    overflow: hidden;
    padding-bottom: 9.6rem;
    .section-title {
        &__title {
            color: $secondary-color;
            text-align: center;
        }
    }
    .section-title__span {
        text-align: center;
    }
    &__main__content {
        padding: 0 15.8rem;
        margin-top: 1.5rem;
        line-height: 1.9rem;
        text-align: center;
        @media (max-width: 1199px) {
            padding: 0;
        }
    }
    &__main {
        margin-top: 2rem;
    }
    &__item {
        margin-top: 2.3rem;
        padding: 0 1rem;
        text-align: center;
        @media (max-width: 991px) {
            width: 50%;
        }
        @media (max-width: 767px) {
            width: 100%;
        }
        &:hover {
            .chooseus__title {
                color: $primary-color;
            }
        }
        .chooseus__title {
            transition: $transition-time;
        }
    }
    &__icons__icon {
        width: 5.425rem;
        height: 5.425rem;
        @media (max-width: 575px) {
            width: 3.425rem;
            height: 3.425rem;
        }
    }
    &__title {
        margin-top: 1.5rem;
        text-align: center;
    }
    &__content {
        margin-top: 0.6rem;
        text-align: center;
        line-height: 1.9rem;
    }
}
