@media (min-width: 1500px) {
  .container {
      max-width: 1460px
  }
}

@media (min-width: 1600px) {
  .container {
      max-width: 1560px;
  }
}

