.reviews {
    overflow: hidden;
    .section-title {
        text-align: center;
    }
    &__description {
        max-width: 36rem;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }
    &__carousel {
        overflow: visible;
        margin-top: 3rem;
    }
    &__item {
        background-color: $base-color;
        padding: 2rem 1.5rem;
        margin: 1rem 0;
        box-shadow: 0px 0px 15px 3px rgba(#000000, .05);
    }
    &__author {
        display: flex;
        align-items: center;
        margin-bottom: 2rem;
    }
    &__img {
        width: 4.5rem;
        height: 4.5rem;
        border-radius: 8px;
        overflow: hidden;
        object-fit: cover;
        margin-right: 1rem;
    }
    &__title {
        margin-bottom: 2rem;
    }
}