.custom-quote {
  background-color: $base-color-darken;
  overflow: hidden;
  .section-title {
    margin-bottom: 2.3rem;
    @media (max-width: 767px) {
      margin-bottom: 1.5rem;
    }
    &__title {
      color: $secondary-color;
      text-align: center;
    }
  }
  .section-title__span {
    text-align: center;
  }
  &__content__col{
    padding-right: 8.6rem;
    @media (max-width: 1499px) {
      padding: 0 15px;
    }
  }
  &__main__content {
    line-height: 1.9rem;
    text-align: center;
    @media (max-width: 991px) {
      margin-top: 2.5rem;
    }
    @media (max-width: 767px) {
    }
    &.second{
      margin-top: 0.9rem;
    }
  }
  &__item {
    width: 67%;
    margin: 0 auto;
    text-align: center;
    @media (max-width: 767px) {
      width: 60%;
    }
    @media (max-width: 575px) {
      width: auto;
    }
  }
  &__icons__icon {
    width: 100%;
  }
  .applinks{
    justify-content: center;
    margin-top: 2.8rem;
  }
  &__order{
    margin-top: 1.2rem;
    text-align: center;
    .btn-own{
      min-width: 25.15rem;
      @media (max-width: 1199px) {
        min-width: unset;
      }
    }
  }
}