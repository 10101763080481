.intro {
    position: relative;
    background-color: $secondary-color;
    color: $text-color-reversed;
    overflow: hidden;

    &--light {
        background-color: $base-color;
        color: $text-color;
    }

    &__inner {
        $inner: &;
        display: flex;
        align-items: center;
        padding-top: 7rem;
        padding-bottom: 3rem;
        min-height: 100vh;
        min-height: calc(100vh - var(--vh-offset, 0px));
        @media (max-width: 991px) {
            padding-top: 7rem;
            min-height: unset;
        }
        &__fade-out-title {
            color: #fff;
        }
        &__content {
            max-width: 100%;
            text-align: center;
            @media (min-width: 992px) {
                width: 55%;
                text-align: left;
            }
        }
        &__bg {
            position: absolute;
            bottom: 0;
            left: 50%;
            width: 50%;
            max-height: calc(100% - 7rem);
            text-align: right;
            @media (max-width: 991px) {
                max-height: calc(100% - 5rem);
            }
            @media (max-width: 991px) {
                display: none;
            }
            img {
                @media (max-width: 1499px) {
                    max-width: 50rem;
                }
            }
        }
        &--reversed {
            justify-content: flex-end;
            #{$inner}__bg {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                left: auto;
                right: 55%;
                height: 100%;
                z-index: 1;
                img {
                    width: 100%;
                    max-width: 45rem;
                    max-height: 100%;
                    object-fit: cover;
                    object-position: top right;
                }
                @media (max-width: 991px) {
                    display: none;
                }
            }
            #{$inner}__content {
                position: relative;
                z-index: 2;
                width: 50%;
                @media (max-width: 1599px) {
                    width: 55%;
                }
                @media (max-width: 1499px) {
                    width: 60%;
                }
                @media (max-width: 991px) {
                    width: 100%;
                }
            }
        }
        &--home {
            #{$inner}__bg {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                right: auto;
                left: 55%;
                height: 100%;
                z-index: 1;
                img {
                    width: 100%;
                    max-width: 45rem;
                    max-height: 100%;
                    margin-right: auto;
                    object-fit: cover;
                    object-position: top left;
                }
                @media (max-width: 991px) {
                    display: none;
                }
            }
            #{$inner}__content {
                position: relative;
                z-index: 2;

                h1 {
                    font-size: 4rem;
                    line-height: 1;
                    margin-bottom: 0;
                }

                p {
                    font-size: 1.5rem;
                }

                width: 55%;

                @media (max-width: 1599px) {
                    width: 55%;
                }
                @media (max-width: 1499px) {
                    width: 60%;
                }
                @media (max-width: 991px) {
                    width: 100%;
                }
                @media (min-width: 992px) {
                    padding: 7rem 0;
                    h1 {
                        font-size: 6rem;
                    }
                }
            }
        }
        &--fullscreen {
            min-height: 100vh;
            min-height: calc(100vh - var(--vh-offset, 0px));
        }
    }

    &__title {
        color: $text-color-reversed;
        text-shadow: 3px 4px 2px #1d3515;

        @media (min-width: 992px) {
            &--float {
                position: absolute;
                top: 0;
                left: 0;

                &:nth-child(1) {
                    top: 1.5rem;
                }
                &:nth-child(2) {
                    top: 4rem;
                }
            }
        }
    }

    &__description {
        color: inherit;
        margin-top: 1rem;
    }

    &__subtitle {
        color: inherit;
        margin-top: 1rem;
        font-size: 160%;
    }
}
