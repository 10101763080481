.offer {
  overflow: hidden;
  .section-title__title {
    color: $secondary-color;
    text-align: center;
  }
  .section-title__span {
    text-align: center;
  }
  &__icons {
    @media (min-width: 992px) {
      padding: 0 16.2rem;
    }
    &__description {
      margin-top: 1rem;
    }
    &__item {
      margin-top: 3.2rem;
      @media (max-width: 767px) {
        text-align: center;
      }
    }
    &__wrapper {
      margin-right: 3rem;
      @media (max-width: 767px) {
        margin: 0 0 1rem;
      }
      img{
        height: 10rem;
        width: 12rem;
        object-fit: cover;
        @media (max-width: 400px) {
          max-width: 100%;
          width: 100%;
          height: auto;
        }
      }
    }
  }
}