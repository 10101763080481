.questions {
  overflow: hidden;
  .section-title__title {
    color: $secondary-color;
    text-align: center;
  }
  .section-title__span {
    text-align: center;
  }
  &__accordion{
    margin-top: 3.4rem;
  }
  &__accordion__item{
    width: 66%;
    padding: 0 1.5rem;
    margin: 0.625rem auto 0;
    border-radius: 0.625rem;
    background: #fcfdfe;
    box-shadow: 0 0.188rem 0.375rem rgba(0, 0, 0, 0.16);
    transition: $transition-time;
    @media (max-width: 1199px){
      width: 100%;
    }
    &.open{
      padding-bottom:1rem;
    }
  }
  &__accordion__title{
    position: relative;
    border-radius: 0.625rem;
    padding: 1rem 2.3rem 1rem 0;
    background: #fcfdfe;
    cursor: pointer;
    transition: 0.2s;
    &:hover{
      color: $primary-color;
    }
    &.active{
      padding-bottom: 1rem;
      margin-bottom: 1rem;
      border-bottom: 0.063rem solid rgba(0, 0, 0, 0.05);
      border-radius: 0;
      color: $primary-color;
      .questions__accordion__span{
        transform: translateY(2px) rotate(-180deg);
        @media (max-width: 575px){
          transform: rotate(-180deg);
        }
      }
    }
  }
  &__accordion__panel{
    max-height: 0;
    overflow: hidden;
    transition: max-height $transition-time ease-out;
  }
  &__accordion__span{
    position: absolute;
    right: 0;
    top: 0.9rem;
  }
}
