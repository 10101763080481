$cookie-bar-bg: $primary-color;
$cookie-bar-text: $base-color;

#cookie-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $cookie-bar-bg;
    color: $cookie-bar-text;
    font-size: 1rem;
    padding: 1rem 2rem;
    z-index: 10;
    box-shadow: 0 0 20px -10px rgba(0, 0, 0, 0.3);
    transition: transform .5s ease-out;
    @media (max-width: 767px) {
        flex-direction: column;
        text-align: center;
    }

    &.active {
        transform: translateY(100%);
    }

    .more-info {
        color: $cookie-bar-text !important;
    }
    .cookie-nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
        white-space: nowrap;
        @media (max-width: 767px) {
            margin-top: 1.5rem;
        }
    }

    .cookie-btn {
        display: inline-block;
        position: relative;
        color: $cookie-bar-bg;
        text-align: center;
        font-weight: 600;
        text-transform: uppercase;
        vertical-align: middle;
        cursor: pointer;
        border: none;
        box-shadow: none;
        background: $cookie-bar-text;
        padding: .4rem 1.4rem;
        margin: 0 1.5rem;
        font-size: 1rem;

        &:focus {
            box-shadow: none;
            outline: 0;
        }
    }
}
