.local-junk {
    overflow: hidden;
    .section-title {
        &__title {
            color: $secondary-color;
            text-align: center;
        }
    }
    .section-title__span {
        text-align: center;
    }
    &__main__content {
        padding: 0 15.8rem;
        margin-top: 1.5rem;
        line-height: 1.9rem;
        text-align: center;
        @media (max-width: 1199px) {
            padding: 0;
        }
    }
    &__list {
        width: 70%;
        margin: 4rem auto 0;
        padding: 0;
        list-style: none;
        column-count: 3;
        @media (max-width: 991px) {
            width: 100%;
        }
        @media (max-width: 767px) {
            column-count: 2;
            font-size: 1rem;
        }
    }
    &__list__item {
        position: relative;
        padding-left: 25%;
        line-height: 1.9rem;
        @media (max-width: 575px) {
            padding-left: 0;
        }
        &:before {
            position: relative;
            content: "●";
            padding-right: 0.8rem;
            color: $primary-color;
            @media (max-width: 767px) {
                padding-right: 0.4rem;
            }
        }
    }
    &__form {
        .form-page__title {
            text-align: center;
        }
        .form-page__input {
            padding: 1rem 2rem;
            border: 0.063rem solid $secondary-color;
            text-align: center;
            &::placeholder {
                color: $text-color;
                font-weight: bold;
            }
        }
        #result, #result-submit {
            font-weight: bold;
            text-align: center;
        }
        .available {
            color: $secondary-color;
        }
        .not-available {
            color: #f7b717;
        }
    }
    &__buttons {
        display: none;

        &.show {
            display: block;
        }
    }
    &__email {
        display: none;

        &.show {
            display: block;
        }
    }
}
