.form-page {
    position: relative;
    max-width: 100%;
    margin-top: 1.5rem;
    @media (max-width: 991px) {
        margin-left: auto;
        margin-right: auto;
    }

    &__wrapper {
        background: $tertiary-color;
        border-radius: 8px;
        padding: 2.5rem;
        margin-top: 1.5rem;
        @media (max-width: 767px) {
            padding: 1.5rem;
        }
    }

    &__input {
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        @media (max-width: 767px) {
            padding: 1rem 2rem;
        }
        &__wrap {
            width: 100%;
            position: relative;
        }
        &--button {
            text-align: left;
            color: #757575;
        }
        &--readonly {
            background-color: lighten($tertiary-color, 30%);
        }
    }
    &__upload {
        position: relative;
        &::after {
            content: "";
            position: absolute;
            top: 50%;
            right: 1.3rem;
            width: 1.3rem;
            height: 1.3rem;
            transform: translateY(-50%);
            background: url("../../img/icons/upload.png");
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            pointer-events: none;
        }
        &__file {
            position: absolute;
            overflow: hidden;
            width: 100%;
            height: 100%;
            opacity: 0;
            z-index: -1;
            pointer-events: none;
        }
    }
    &__button {
        &__wrap {
            width: 100%;
            .btn-own {
                width: 100%;
                padding: 0.7rem 3rem;
                font-size: 1.4rem;
                img {
                    position: absolute;
                    top: 50%;
                    right: 2rem;
                    transform: translateY(-50%);
                }
            }
        }
    }
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"] {
    -moz-appearance: textfield;
}
