.section-title {
    &__title {
        margin-bottom: 1rem;
    }
    &__span {
        display: block;
        color: $primary-color;
        font-weight: 600;
        text-transform: uppercase;
        //margin-bottom: .5rem;
        margin-bottom: 1rem;
    }
    &__description {
        font-weight: 300;
    }
}