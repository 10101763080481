.fairprice {
  padding: 5rem 0 4.8rem;
  overflow: hidden;
  .section-title {
    &__title {
      color: $secondary-color;
      text-align: center;
    }
  }
  .section-title__span {
    text-align: center;
  }
  &__description {
    padding: 0 14.1rem;
    text-align: center;
    @media (max-width: 991px) {
      padding: 0;
    }
  }
  &__main {
    margin-top: 3rem;
  }
  &__item {
    height: 19.5rem;
    border-radius: 0.5rem;
    margin-top: 3.05rem;
    background: $base-color-darken;
    box-shadow: 0 0.375rem 1.5rem rgba(0, 0, 0, 0.1);
    text-align: center;
    cursor: pointer;
    transition: $transition-time;
    @media (max-width: 767px) {
      margin-top: 2rem;
      height: 15rem;
    }
    &:hover {
      box-shadow: 0 0.375rem 1.5rem rgba(0, 0, 0, 0.3);
    }
  }
  &__title {
    padding: 0 1.2rem;
    color: $secondary-color;
    font-size: 2rem;
  }
  &__button__wrap {
    margin-top: 3.2rem;
    text-align: center;
  }
}