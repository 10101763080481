.facts {
    background-color: $base-color-darken;
    @media (max-width: 1199px) {
        overflow: hidden;
    }
    .section-title__title {
        color: $secondary-color;
    }
    &__number {
        display: block;
        margin-bottom: 1rem;
        color: $primary-color;
    }
    &__image {
        text-align: center;
        @media (min-width: 1200px) {
            margin-bottom: -10rem;
        }
        &__img {
            max-width: 100%;
            height: auto;
        }
    }
    &__icons {
        display: flex;
        flex-wrap: wrap;
        margin: 3rem 0 2rem 0;
        &__item {
            width: calc(100% / 3);
            padding-right: 1rem;
            @media (max-width: 767px) {
                width: 100%;
                padding-right: 0;
                margin-bottom: 2rem;
                // display: flex;
                // align-items: center;
            }
        }
        &__title {
            margin-bottom: 1rem;
        }
        &__description {
            @media (min-width: 768px) {
                max-width: 22rem;
            }
        }
        &__icon {
            margin-bottom: 1rem;
            @media (max-width: 767px) {
                width: 5rem;
                height: 5rem;
                object-fit: contain;
                margin-right: 2rem;
            }
            @media (max-width: 575px) {
                width: 4rem;
                height: 4rem;
            }
        }
    }
}