footer {
    margin-top: auto;
}
.footer {
    position: relative;
    font-size: 1rem;
    color: $text-color-reversed;
    background-color: $secondary-color;
    text-align: left;
    @media (max-width: 991px) {
        text-align: center;
    }
    &__copyright {
        margin: 2rem 0;
    }
    &__logo {
        max-width: 15rem;
        &__img {
            max-width: 100%;
            height: auto;
        }
        @media (max-width: 991px) {
            margin-left: auto;
            margin-right: auto;
        }
    }
    &__menu {
        list-style: none;
        margin: 0;
        padding: 0;
        &__item {
            margin: 1.5rem 0;
            font-weight: 400;
            &:last-child {
                margin-bottom: 0;
            }
        }
        &__link {
            color: $text-color-reversed;
            transition: color .3s;
            &:hover {
                color: $primary-color;
            }
        }
    }
    &__social {
        display: flex;
        justify-content: space-between;
        max-width: 18rem;
        @media (max-width: 991px) {
            margin: 0 auto;
        }
        &__link {
            display: block;
        }
        &__img {
            width: 1.6rem;
            height: 1.6rem;
        }
    }
    &__partners {
        display: flex;
        justify-content: space-between;
        max-width: 20rem;
        margin-top: 2rem;
        @media (max-width: 991px) {
            margin: 2rem auto 0;
        }
        &__img {
            width: 3.5rem;
            height: 3.5rem;
            object-fit: contain;
            padding-right: .5rem;
            @media (max-width: 991px) {
                padding: 0 .5rem;
            }
        }
    }
}