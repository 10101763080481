.error-page {
    margin: 13rem auto;
    max-width: 70rem;
    position: relative;
    padding: 0 1rem;
    color: #000000;
    h1 {
        font-size: 6rem;
        font-weight: bold;
        margin-bottom: .5rem;
        color: inherit;
    }

    h2 {
        font-size: 3rem;
        font-weight: 600;
        margin-bottom: .5rem;
        color: inherit;
    }

    img {
        max-width: 100%;
        height: auto;
        width: 20rem;
    }

    @media (max-width: 991px) {
        margin: 4rem auto;
        img {
            width: 15rem;
        }
    }

    .link-home {
        display: inline-block;
        padding: .7rem 1.5rem;
        font-size: 1.5rem;
        background: #ffffff;
        color: #000000;
        border: 1px solid #000000;
        margin-top: 1rem;
        transition: all .5s;

        &:hover {
            background: #000000;
            color: #ffffff;
        }
    }
    .navbar-brand {
        position: relative;
        bottom: 0.4rem;
        left: 3rem;
        min-height: 5.5rem;
        display: flex;
        align-items: center;
        color: $text-color !important;
        font-family: $base-font;
        font-size: 5rem;
        transition: hover .5s;
        letter-spacing: 0.1rem;
        &:hover{
            color: darken($text-color, 15%);
        }
    }

}
