.howjunk {
  background-color: $base-color-darken;
  overflow: hidden;
  .section-title {
    &__title {
      color: $secondary-color;
      text-align: center;
    }
  }
  .section-title__span {
    text-align: center;
  }
  &__item {
    margin-top: 2.3rem;
    &:hover {
      .howjunk__title {
        color: $primary-color;
      }
    }
    .howjunk__title {
      transition: $transition-time;
    }
  }
  &__icons__icon {
    width: 100%;
    border-radius: 0.5rem;
  }
  &__title {
    margin-top: 1.5rem;
    text-align: center;
  }
  &__content {
    margin-top: 0.6rem;
    text-align: center;
    line-height: 1.9rem;
  }
}

.tell__us__about {
  background: none;
}