.btn-own {
    display: inline-block;
    position: relative;
    padding: 1rem 2rem;
    border-radius: 5rem;
    border: 1px solid $primary-color;
    outline: none;
    font-size: $font-size;
    font-weight: 500;
    text-align: center;
    text-transform: uppercase;
    background-color: $primary-color;
    color: $text-color-reversed;
    transition: all 0.3s;
    z-index: 5;
    @media (min-width: 992px) {
        padding: 1rem 3rem;
    }
    &:hover {
        background-color: darken($primary-color, 10%);
        border-color: darken($primary-color, 10%);
        color: $text-color-reversed;
        box-shadow: 0px 8px 10px 0px rgba($primary-color, 0.25);
    }
    &:focus {
        outline: none;
        box-shadow: 0px 8px 10px 0px rgba($primary-color, 0.25);
    }
    &--reversed {
        background-color: transparent;
        color: $primary-color;
    }

    &--w-icon {
        display: flex;
        align-items: center;
    }

    &__icon {
        width: 2rem;
        height: 2rem;
        object-fit: contain;
        margin-right: 0.5rem;
    }
}
