.newsletter {
    background-color: $tertiary-color;
    color: $text-color-reversed;
    overflow: hidden;
    &__inner {
        position: relative;
        text-align: center;
    }
    &__img {
        position: absolute;
        bottom: 0;
        max-height: 95%;
        max-width: 20rem;
        @media (max-width: 991px) {
            display: none;
        }
        @media (min-width: 1500px) {
            max-width: 25rem;
        }
        &--phone {
            left: -9rem;
            @media (min-width: 1200px) {
                left: -6rem;
            }
            @media (min-width: 1500px) {
                left: 0;
            }
        }
        &--guy {
            right: -9rem;
            @media (min-width: 1200px) {
                right: -6rem;
            }
            @media (min-width: 1500px) {
                right: 0;
            }
        }
    }
    &__form {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin: 2rem 0;
    }
    &__input {
        max-width: 100%;
        width: 27rem;
        &__wrap {
            max-width: 100%;
            position: relative;
            margin: .5rem;
            &::after {
                content: '';
                position: absolute;
                top: 50%;
                left: 1.3rem;
                width: 1.3rem;
                height: 1.3rem;
                transform: translateY(-50%);
                background: url('../img/newsletter/input-icon.png');
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
            }
        }
    }
    &__button {
        &__wrap {
            max-width: 100%;
            margin: .5rem;
        }
    }
    .section-title__description{
        @media (min-width: 992px) {
            padding: 0 24%;
        }
    }
    .btn-own a{
        color: #fff;
    }
}