p {
    margin-bottom: 0;
    line-height: 1.6;
}

h1,
h2,
h3,
h4,
h5,
.h1,
.h2,
.h3,
.h4,
.h5 {
  font-family: $second-font;
  line-height: 1.2;
  color: inherit;
}

.h1 {
    display: inline-block;
    color: $text-color-reversed;
    font-size: 3.75rem;
    font-weight: 500;
    @media (max-width: 1499px) {
        font-size: 3.5rem;
    }
    @media (max-width: 1199px) {
        font-size: 3rem;
    }
    @media (max-width: 991px) {
        font-size: 3.5rem;
    }
    @media (max-width: 767px) {
        font-size: 3rem;
    }
    @media (max-width: 575px) {
        font-size: 2.8rem;
    }
    @media (max-width: 425px) {
        font-size: 2.5rem;
    }
}

.h2 {
    font-size: 3rem;
    font-weight: 500;
    margin-bottom: 0;
    line-height: 1.1;
    @media (max-width: 1499px) {
        font-size: 2.7rem;
    }
    @media (max-width: 1199px) {
        font-size: 2.3rem;
    }
    @media (max-width: 991px) {
        font-size: 3rem;
    }
    @media (max-width: 767px) {
        font-size: 2.3rem;
    }
    @media (max-width: 425px) {
        font-size: 2rem;
    }
}

.h3 {
    font-size: 1.3rem;
    @media (max-width: 1199px) {
        font-size: 1.2rem;
    }
}

.h4 {
    font-size: 1.9rem;
    font-weight: bold;
    color: $primary-color;
    margin-bottom: 0;
    line-height: 1.2;
    @media (max-width: 1499px) {
        font-size: 1.8rem;
    }
    @media (max-width: 1199px) {
        font-size: 1.7rem;
    }
}

.h5 {
    font-size: 1.6rem;
    font-weight: bold;
    color: $secondary-color;
    margin-bottom: 0;
    line-height: 1.2;
    @media (max-width: 1499px) {
        font-size: 1.5rem;
    }
    @media (max-width: 1199px) {
        font-size: 1.4rem;
    }
}

.font-size-120 {
    font-size: 120%;
}

@each $name, $color in $colors {
    .u-text-color-#{$name} {
        color: $color;
    }
}
