/*Base variables*/
$base-font: 'Montserrat', sans-serif;
$second-font: 'Jellee Roman', sans-serif;

$font-size: 1.12rem;

$base-color: #ffffff;
$base-color-darken: #f5f6fb;
$primary-color: #8dcf3f;
$secondary-color: #354154;
$tertiary-color: #5d6e8a;

$text-color: #080807;
$text-color-reversed: #ffffff;

$padding-lg: 5rem 0;
$padding-sm: 3rem 0;

$focus: 0px 12px 48px 0px rgba(0, 79, 149, .5);

$transition-time: .3s;

$colors: (
    "base": $base-color,
    "primary": $primary-color,
    "secondary": $secondary-color,
    "tertiary": $tertiary-color,
);
