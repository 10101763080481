/*General*/
html {
  font-size: 85%;
  @media (min-width: 1200px) {
    font-size: 90%;
  }
  @media (min-width: 1500px) {
    font-size: 100%;
  }
}

a {
  color: $text-color;
  outline: 0;

  &:hover {
    color: lighten($text-color, 10%);
    text-decoration: none;
  }
}

body {
  background: $base-color;
  font-size: $font-size;
  font-family: $base-font;
  font-weight: 500;
  color: $text-color;
  overflow-x: hidden;
  min-width: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  @media (max-width: 575px) {
    font-size: 1.1rem;
  }
}
