.about {
    overflow: hidden;
    .section-title__title {
        text-transform: uppercase;
        color: $secondary-color;
        font-size: 2.5rem;
    }
    &__image {
        position: relative;
        @media (min-width: 1200px) {
            margin-right: 2rem;
        }
        &__img {
            max-width: 100%;
            height: auto;
            width: 100%;
        }
        &__icon {
            position: absolute;
            width: 10rem;
            height: 10rem;
            top: 50%;
            left: 50%;
            z-index: 1;
            transform: translate(-50%,-50%);
        }
    }
    &__description {
        max-width: 50rem;
        margin-top: 2rem;
    }
    &__icons {
        display: flex;
        flex-wrap: wrap;
        margin: 2rem 0;
        &__item {
            width: calc(100% / 3);
            padding-right: 1rem;
            text-align: center;
            @media (max-width: 767px) {
                width: 100%;
                padding-right: 0;
                display: flex;
                align-items: center;
            }
            @media (max-width: 575px){
                //text-align: left;
            }
        }
        &__icon {
            margin-bottom: 1rem;
            @media (max-width: 767px) {
                width: 5rem;
                height: 5rem;
                object-fit: contain;
                margin-right: 2rem;
            }
            @media (max-width: 575px) {
                width: 4rem;
                height: 4rem;
            }
        }
        &__description {
            font-weight:700;
            @media (max-width: 767px) {
                max-width: 100%;
            }
        }
    }
}