.orderform {
  padding: 3.7rem 0 6.3rem;
  overflow: hidden;
  .section-title {
    margin-bottom: 3.4rem;
    @media (max-width: 767px) {
      margin-bottom: 1.5rem;
    }
    &__title {
      color: $secondary-color;
      text-align: center;
    }
  }
  .section-title__span {
    text-align: center;
  }
  .form-page {
    margin-top: 3.2rem;
    &__form__order, &__order__detail {
      width: 32%;
      @media (max-width: 991px) {
        width: 45%;
      }
      @media (max-width: 767px) {
        width: 100%;
      }
    }
    &__form__order {
      margin-top: 1.5rem;
      margin-right: 8rem;
      @media (max-width: 767px) {
        margin-right: 0;
      }
      .form-page__title {
        margin: 0 0 1.4rem 1.8rem
      }
    }
    &__input {
      padding: 1rem 2rem;
      border: 0.063rem solid $secondary-color;
      &::placeholder {
        color: $text-color;
        font-weight: bold;
      }
    }
    &__form__item {
      margin-bottom: 0.938rem;
    }
    .checkbox {
      position: relative;
      display: block;
      width: 100%;
      margin-top: 1.7rem;
      .checkbox-label {
        position: relative;
        display: block;
        padding-left: 2.75rem;
        margin-bottom: 0;
        font-size: 1.12rem;
        font-weight: bold;
        cursor: pointer;
        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: -0.15rem;
          height: 1.75rem;
          width: 1.75rem;
          border-radius: 0.3rem;
          border: 0.063rem solid $secondary-color;
          transition: transform 0.3s ease;
        }
        &:after {
          content: '';
          position: absolute;
          top: 0.4rem;
          left: 0.563rem;
          display: block;
          width: 0.75rem;
          height: 0.438rem;
          border-bottom: 0.188rem solid #8dcf3f;
          border-left: 0.188rem solid #8dcf3f;
          transform: rotate(-45deg) scale(0);
          transition: transform ease 0.3s;
        }
      }
      input[type="checkbox"] {
        position: absolute;
        left: 0;
        opacity: 0;
        &:checked ~ label {
          &:before {
            border: 0.063rem solid $primary-color;

          }
          &:after {
            transform: rotate(-45deg) scale(1);
          }
        }
        &:focus + label::before {
          outline: 0;
        }
      }
    }
    &__main__content {
      margin-bottom: 1.2rem;
    }
    &__type__content {
      margin-bottom: 0.15rem;
    }
    &__pick__up, &__summary {
      margin-top: 2.7rem;
    }
    &__pick__up {
      color: $primary-color;
    }
    &__price {
      margin-top: 0.1rem;
      color: $primary-color;
      font-size: 3rem;
      font-weight: bold;
      font-family: $second-font;
      @media (max-width: 991px) {
        font-size: 2rem;
      }
      @media (max-width: 767px) {
        font-size: 1.5rem;
      }
    }
    &__order__detail {
      padding: 3rem 3rem 1rem;
      border-radius: 0.5rem;
      box-shadow: 0 0.375rem 1.5rem rgba(0, 0, 0, 0.1);
      @media (max-width: 767px) {
        padding: 3rem 2rem 2rem;
        margin-top: 3rem
      }
      .form-page__title {
        margin: 0 0 1.9rem;
      }
    }
  }
  &__button__wrap {
    margin-top: 2.75rem;
    text-align: center;
    .btn-own {
      width: 100%;
    }
  }
}