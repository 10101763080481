.applinks {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    &__img {
        max-width: 100%;
        height: auto;
        @media (max-width: 991px) {
            max-width: 10rem;
            height: auto;
        }
    }
    &--intro {
        @media (min-width: 992px) {
            justify-content: flex-start;
        }
    }
}