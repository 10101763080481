.section {
    padding: $padding-sm;
    @media (min-width: 992px) {
        padding: $padding-lg;
    }

    &--intro {
        padding-top: 12rem; // 7rem Header offset + 5rem section offset
        @media (max-width: 991px) {
            padding-top: 9rem;
        }
    }
}
