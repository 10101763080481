input {
    background-color: $base-color;
    padding: 1rem 3rem;
    border-radius: 5rem;
    border: 1px solid $base-color;
    outline: none;
    font-size: $font-size;
    font-weight: 500;
    box-shadow: none;
    -webkit-appearance: none;
    transition: box-shadow .3s, border-color .3s;
    &:focus {
        border-color: $primary-color;
        box-shadow: 0px 0px 15px 0px rgba($primary-color, .25);
    }
}