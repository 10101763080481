.intro-junk, .intro-about-us {
  .intro__inner__bg {
    bottom: -7.1%;
    left: 42.3%;
    @media (max-width: 1499px) {
      bottom: 5%;
      left: 42.3%;
    }
    img {
      @media (max-width: 1499px) {
        max-width: 35rem;
      }
    }
  }
  .intro__inner {
    padding-top: 9rem;
    padding-bottom: 4rem;
  }
  .intro__inner__content {
    position: relative;
    z-index: 1;
    margin-top: 1.1rem;
  }
}
.intro-about-us {
  .intro__inner__bg {
    z-index: 0;
    bottom: -7.2%;
    left: 42.6%;
  }
}