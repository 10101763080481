.activejunkers {
  overflow: hidden;
  .section-title {
    &__title {
      color: $secondary-color;
      text-align: center;
      @media (max-width: 575px) {
        br {
          display: none;
        }
      }
    }
  }
  .section-title__span {
    text-align: center;
  }
  &__item {
    margin-top: 2.3rem;
    &:hover {
      .activejunkers__title {
        color: $primary-color;
      }
    }
    .activejunkers__title {
      transition: $transition-time;
    }
  }
  &__icons__icon {
    width: 100%;
    border-radius: 0.5rem;
  }
  &__title {
    margin-top: 1.5rem;
    text-align: center;
  }
}