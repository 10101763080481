/*Header*/
.header {
    position: fixed;
    width: 100%;
    color: $text-color-reversed;
    background-color: $secondary-color;
    z-index: 10;
    &__inner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 2rem 0;
        @media (max-width: 991px) {
            padding: 1rem 0;
        }
    }
    &__logo {
        max-width: 16rem;
        @media (max-width: 1200px) {
            max-width: 11rem;
        }
        @media (max-width: 991px) {
            padding-right: 2rem;
            max-width: 14rem;
        }
        &__img {
            max-width: 100%;
            height: auto;
        }
    }
    &__nav {
        @media (min-width: 992px) {
            display: flex;
            align-items: center;

            &__btn {
                margin-left: 1rem;

                &.btn-own {
                    padding: 0.8rem 1.5rem;
                }
            }
        }
        @media (max-width: 991px) {
            position: fixed;
            top: 0;
            right: 0;
            height: 100vh;
            background-color: $tertiary-color;
            width: 20rem;
            max-width: 80%;
            transform: translateX(100%);
            transition: transform 0.3s;
            overflow-y: auto;
            z-index: 10;
            &.open {
                transform: translateX(0);
            }
        }
    }
    &__menu {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        @media (min-width: 992px) {
            text-align: center;
            align-items: center;
        }
        @media (max-width: 991px) {
            flex-direction: column;
            height: 100%;
            text-align: center;
        }
        &__item {
            margin: 0 1rem;
            font-weight: 500;
            a {
                color: inherit;
                transition: color 0.3s;
            }
            @media (max-width: 1199px) and (min-width: 992px) {
                font-size: 1rem;
                margin: 0 0.7rem;
            }
            @media (min-width: 992px) {
                &:first-child {
                    margin-left: 0;
                }
                &:last-child {
                    margin-right: 0;
                }
            }
            @media (max-width: 991px) {
                position: relative;
                margin: 0;
                width: 100%;
                text-transform: uppercase;
                a {
                    display: block;
                    padding: 1.5rem 2.5rem;
                }
                &::after {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    background-color: lighten($tertiary-color, 5%);
                    height: 1px;
                }
                &:first-child {
                    margin-top: auto;
                }
                &:last-child {
                    margin-bottom: auto;
                    &::after {
                        display: none;
                    }
                }
            }
            &:hover,
            &.active {
                color: $primary-color;
            }
        }
    }
    &__buttons {
        @media (max-width: 991px) {
            display: none;
        }
        .btn-own {
            padding: 0.5rem 2rem;
            text-transform: unset;
            margin-left: 0.5rem;
        }
    }
    &__burger {
        @media (min-width: 992px) {
            display: none;
        }
        &__img {
            box-shadow: 0px 12px 24px 0px rgba($primary-color, 0.35);
            border-radius: 50%;
        }
    }
}
.bd-info {
    .headroom--top {
        background: none;
    }
}
