.info {
  padding: 5rem 0 2.5rem;
  &__main{
    width: 1100px;
    margin: 0 auto;
    @media (max-width: 1199px){
      width:unset;
    }
  }
  &__item {
    margin: 3rem 0;
    &:hover {
      .activejunkers__title {
        color: $primary-color;
      }
    }
  }
  &__icons__icon {
    width: 100%;
  }
  &__description{
    margin-bottom: 2.3rem;
    a{
      color: $primary-color;
    }
  }
}