.service-areas {
  overflow: hidden;
  background-color: $base-color-darken;
  .section-title {
    margin-bottom: 2.3rem;
    &__title {
      color: $secondary-color;
      text-align: center;
    }
  }
  .section-title__span {
    text-align: center;
  }
  &__main__content {
    line-height: 1.9rem;
    @media (max-width: 991px) {
      margin-top: 2.5rem;
      text-align: center;
    }
    @media (max-width: 767px) {
    }
  }
  &__item {
    padding-right: 3rem;
    text-align: center;
    @media (max-width: 991px) {
      width: 80%;
      padding: 0;
      margin: 0 auto;
    }
    @media (max-width: 767px) {
      width: auto;
    }
  }
  &__icons__icon {
    width: 100%;
  }
  .btn-own{
    pointer-events: none;
  }
}