@charset "UTF-8";
/*Base variables*/
@font-face {
  font-family: "Jellee Roman";
  src: url("../../fonts/Jellee-Roman.woff2") format("woff2"), url("../../fonts/Jellee-Roman.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
/*General*/
html {
  font-size: 85%;
}
@media (min-width: 1200px) {
  html {
    font-size: 90%;
  }
}
@media (min-width: 1500px) {
  html {
    font-size: 100%;
  }
}

a {
  color: #080807;
  outline: 0;
}
a:hover {
  color: #23231f;
  text-decoration: none;
}

body {
  background: #ffffff;
  font-size: 1.12rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  color: #080807;
  overflow-x: hidden;
  min-width: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
}
@media (max-width: 575px) {
  body {
    font-size: 1.1rem;
  }
}

/*Header*/
.header {
  position: fixed;
  width: 100%;
  color: #ffffff;
  background-color: #354154;
  z-index: 10;
}
.header__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 0;
}
@media (max-width: 991px) {
  .header__inner {
    padding: 1rem 0;
  }
}
.header__logo {
  max-width: 16rem;
}
@media (max-width: 1200px) {
  .header__logo {
    max-width: 11rem;
  }
}
@media (max-width: 991px) {
  .header__logo {
    padding-right: 2rem;
    max-width: 14rem;
  }
}
.header__logo__img {
  max-width: 100%;
  height: auto;
}
@media (min-width: 992px) {
  .header__nav {
    display: flex;
    align-items: center;
  }
  .header__nav__btn {
    margin-left: 1rem;
  }
  .header__nav__btn.btn-own {
    padding: 0.8rem 1.5rem;
  }
}
@media (max-width: 991px) {
  .header__nav {
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    background-color: #5d6e8a;
    width: 20rem;
    max-width: 80%;
    transform: translateX(100%);
    transition: transform 0.3s;
    overflow-y: auto;
    z-index: 10;
  }
  .header__nav.open {
    transform: translateX(0);
  }
}
.header__menu {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
}
@media (min-width: 992px) {
  .header__menu {
    text-align: center;
    align-items: center;
  }
}
@media (max-width: 991px) {
  .header__menu {
    flex-direction: column;
    height: 100%;
    text-align: center;
  }
}
.header__menu__item {
  margin: 0 1rem;
  font-weight: 500;
}
.header__menu__item a {
  color: inherit;
  transition: color 0.3s;
}
@media (max-width: 1199px) and (min-width: 992px) {
  .header__menu__item {
    font-size: 1rem;
    margin: 0 0.7rem;
  }
}
@media (min-width: 992px) {
  .header__menu__item:first-child {
    margin-left: 0;
  }
  .header__menu__item:last-child {
    margin-right: 0;
  }
}
@media (max-width: 991px) {
  .header__menu__item {
    position: relative;
    margin: 0;
    width: 100%;
    text-transform: uppercase;
  }
  .header__menu__item a {
    display: block;
    padding: 1.5rem 2.5rem;
  }
  .header__menu__item::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #687a99;
    height: 1px;
  }
  .header__menu__item:first-child {
    margin-top: auto;
  }
  .header__menu__item:last-child {
    margin-bottom: auto;
  }
  .header__menu__item:last-child::after {
    display: none;
  }
}
.header__menu__item:hover, .header__menu__item.active {
  color: #8dcf3f;
}
@media (max-width: 991px) {
  .header__buttons {
    display: none;
  }
}
.header__buttons .btn-own {
  padding: 0.5rem 2rem;
  text-transform: unset;
  margin-left: 0.5rem;
}
@media (min-width: 992px) {
  .header__burger {
    display: none;
  }
}
.header__burger__img {
  box-shadow: 0px 12px 24px 0px rgba(141, 207, 63, 0.35);
  border-radius: 50%;
}

.bd-info .headroom--top {
  background: none;
}

footer {
  margin-top: auto;
}

.footer {
  position: relative;
  font-size: 1rem;
  color: #ffffff;
  background-color: #354154;
  text-align: left;
}
@media (max-width: 991px) {
  .footer {
    text-align: center;
  }
}
.footer__copyright {
  margin: 2rem 0;
}
.footer__logo {
  max-width: 15rem;
}
.footer__logo__img {
  max-width: 100%;
  height: auto;
}
@media (max-width: 991px) {
  .footer__logo {
    margin-left: auto;
    margin-right: auto;
  }
}
.footer__menu {
  list-style: none;
  margin: 0;
  padding: 0;
}
.footer__menu__item {
  margin: 1.5rem 0;
  font-weight: 400;
}
.footer__menu__item:last-child {
  margin-bottom: 0;
}
.footer__menu__link {
  color: #ffffff;
  transition: color 0.3s;
}
.footer__menu__link:hover {
  color: #8dcf3f;
}
.footer__social {
  display: flex;
  justify-content: space-between;
  max-width: 18rem;
}
@media (max-width: 991px) {
  .footer__social {
    margin: 0 auto;
  }
}
.footer__social__link {
  display: block;
}
.footer__social__img {
  width: 1.6rem;
  height: 1.6rem;
}
.footer__partners {
  display: flex;
  justify-content: space-between;
  max-width: 20rem;
  margin-top: 2rem;
}
@media (max-width: 991px) {
  .footer__partners {
    margin: 2rem auto 0;
  }
}
.footer__partners__img {
  width: 3.5rem;
  height: 3.5rem;
  object-fit: contain;
  padding-right: 0.5rem;
}
@media (max-width: 991px) {
  .footer__partners__img {
    padding: 0 0.5rem;
  }
}

p {
  margin-bottom: 0;
  line-height: 1.6;
}

h1,
h2,
h3,
h4,
h5,
.h1,
.h2,
.h3,
.h4,
.h5 {
  font-family: "Jellee Roman", sans-serif;
  line-height: 1.2;
  color: inherit;
}

.h1 {
  display: inline-block;
  color: #ffffff;
  font-size: 3.75rem;
  font-weight: 500;
}
@media (max-width: 1499px) {
  .h1 {
    font-size: 3.5rem;
  }
}
@media (max-width: 1199px) {
  .h1 {
    font-size: 3rem;
  }
}
@media (max-width: 991px) {
  .h1 {
    font-size: 3.5rem;
  }
}
@media (max-width: 767px) {
  .h1 {
    font-size: 3rem;
  }
}
@media (max-width: 575px) {
  .h1 {
    font-size: 2.8rem;
  }
}
@media (max-width: 425px) {
  .h1 {
    font-size: 2.5rem;
  }
}

.h2 {
  font-size: 3rem;
  font-weight: 500;
  margin-bottom: 0;
  line-height: 1.1;
}
@media (max-width: 1499px) {
  .h2 {
    font-size: 2.7rem;
  }
}
@media (max-width: 1199px) {
  .h2 {
    font-size: 2.3rem;
  }
}
@media (max-width: 991px) {
  .h2 {
    font-size: 3rem;
  }
}
@media (max-width: 767px) {
  .h2 {
    font-size: 2.3rem;
  }
}
@media (max-width: 425px) {
  .h2 {
    font-size: 2rem;
  }
}

.h3 {
  font-size: 1.3rem;
}
@media (max-width: 1199px) {
  .h3 {
    font-size: 1.2rem;
  }
}

.h4 {
  font-size: 1.9rem;
  font-weight: bold;
  color: #8dcf3f;
  margin-bottom: 0;
  line-height: 1.2;
}
@media (max-width: 1499px) {
  .h4 {
    font-size: 1.8rem;
  }
}
@media (max-width: 1199px) {
  .h4 {
    font-size: 1.7rem;
  }
}

.h5 {
  font-size: 1.6rem;
  font-weight: bold;
  color: #354154;
  margin-bottom: 0;
  line-height: 1.2;
}
@media (max-width: 1499px) {
  .h5 {
    font-size: 1.5rem;
  }
}
@media (max-width: 1199px) {
  .h5 {
    font-size: 1.4rem;
  }
}

.font-size-120 {
  font-size: 120%;
}

.u-text-color-base {
  color: #ffffff;
}

.u-text-color-primary {
  color: #8dcf3f;
}

.u-text-color-secondary {
  color: #354154;
}

.u-text-color-tertiary {
  color: #5d6e8a;
}

@media (min-width: 1500px) {
  .container {
    max-width: 1460px;
  }
}
@media (min-width: 1600px) {
  .container {
    max-width: 1560px;
  }
}
.headroom {
  width: 100%;
  transition: transform 0.3s ease-in-out, background-color 0.3s;
  will-change: transform;
}

.headroom--unpinned {
  transform: translateY(-100%);
}

.section {
  padding: 3rem 0;
}
@media (min-width: 992px) {
  .section {
    padding: 5rem 0;
  }
}
.section--intro {
  padding-top: 12rem;
}
@media (max-width: 991px) {
  .section--intro {
    padding-top: 9rem;
  }
}

.section-title__title {
  margin-bottom: 1rem;
}
.section-title__span {
  display: block;
  color: #8dcf3f;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 1rem;
}
.section-title__description {
  font-weight: 300;
}

.btn-own {
  display: inline-block;
  position: relative;
  padding: 1rem 2rem;
  border-radius: 5rem;
  border: 1px solid #8dcf3f;
  outline: none;
  font-size: 1.12rem;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
  background-color: #8dcf3f;
  color: #ffffff;
  transition: all 0.3s;
  z-index: 5;
}
@media (min-width: 992px) {
  .btn-own {
    padding: 1rem 3rem;
  }
}
.btn-own:hover {
  background-color: #73af2c;
  border-color: #73af2c;
  color: #ffffff;
  box-shadow: 0px 8px 10px 0px rgba(141, 207, 63, 0.25);
}
.btn-own:focus {
  outline: none;
  box-shadow: 0px 8px 10px 0px rgba(141, 207, 63, 0.25);
}
.btn-own--reversed {
  background-color: transparent;
  color: #8dcf3f;
}
.btn-own--w-icon {
  display: flex;
  align-items: center;
}
.btn-own__icon {
  width: 2rem;
  height: 2rem;
  object-fit: contain;
  margin-right: 0.5rem;
}

.applinks {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.applinks__img {
  max-width: 100%;
  height: auto;
}
@media (max-width: 991px) {
  .applinks__img {
    max-width: 10rem;
    height: auto;
  }
}
@media (min-width: 992px) {
  .applinks--intro {
    justify-content: flex-start;
  }
}

.intro {
  position: relative;
  background-color: #354154;
  color: #ffffff;
  overflow: hidden;
}
.intro--light {
  background-color: #ffffff;
  color: #080807;
}
.intro__inner {
  display: flex;
  align-items: center;
  padding-top: 7rem;
  padding-bottom: 3rem;
  min-height: 100vh;
  min-height: calc(100vh - var(--vh-offset, 0px));
}
@media (max-width: 991px) {
  .intro__inner {
    padding-top: 7rem;
    min-height: unset;
  }
}
.intro__inner__fade-out-title {
  color: #fff;
}
.intro__inner__content {
  max-width: 100%;
  text-align: center;
}
@media (min-width: 992px) {
  .intro__inner__content {
    width: 55%;
    text-align: left;
  }
}
.intro__inner__bg {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 50%;
  max-height: calc(100% - 7rem);
  text-align: right;
}
@media (max-width: 991px) {
  .intro__inner__bg {
    max-height: calc(100% - 5rem);
  }
}
@media (max-width: 991px) {
  .intro__inner__bg {
    display: none;
  }
}
@media (max-width: 1499px) {
  .intro__inner__bg img {
    max-width: 50rem;
  }
}
.intro__inner--reversed {
  justify-content: flex-end;
}
.intro__inner--reversed .intro__inner__bg {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  left: auto;
  right: 55%;
  height: 100%;
  z-index: 1;
}
.intro__inner--reversed .intro__inner__bg img {
  width: 100%;
  max-width: 45rem;
  max-height: 100%;
  object-fit: cover;
  object-position: top right;
}
@media (max-width: 991px) {
  .intro__inner--reversed .intro__inner__bg {
    display: none;
  }
}
.intro__inner--reversed .intro__inner__content {
  position: relative;
  z-index: 2;
  width: 50%;
}
@media (max-width: 1599px) {
  .intro__inner--reversed .intro__inner__content {
    width: 55%;
  }
}
@media (max-width: 1499px) {
  .intro__inner--reversed .intro__inner__content {
    width: 60%;
  }
}
@media (max-width: 991px) {
  .intro__inner--reversed .intro__inner__content {
    width: 100%;
  }
}
.intro__inner--home .intro__inner__bg {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  right: auto;
  left: 55%;
  height: 100%;
  z-index: 1;
}
.intro__inner--home .intro__inner__bg img {
  width: 100%;
  max-width: 45rem;
  max-height: 100%;
  margin-right: auto;
  object-fit: cover;
  object-position: top left;
}
@media (max-width: 991px) {
  .intro__inner--home .intro__inner__bg {
    display: none;
  }
}
.intro__inner--home .intro__inner__content {
  position: relative;
  z-index: 2;
  width: 55%;
}
.intro__inner--home .intro__inner__content h1 {
  font-size: 4rem;
  line-height: 1;
  margin-bottom: 0;
}
.intro__inner--home .intro__inner__content p {
  font-size: 1.5rem;
}
@media (max-width: 1599px) {
  .intro__inner--home .intro__inner__content {
    width: 55%;
  }
}
@media (max-width: 1499px) {
  .intro__inner--home .intro__inner__content {
    width: 60%;
  }
}
@media (max-width: 991px) {
  .intro__inner--home .intro__inner__content {
    width: 100%;
  }
}
@media (min-width: 992px) {
  .intro__inner--home .intro__inner__content {
    padding: 7rem 0;
  }
  .intro__inner--home .intro__inner__content h1 {
    font-size: 6rem;
  }
}
.intro__inner--fullscreen {
  min-height: 100vh;
  min-height: calc(100vh - var(--vh-offset, 0px));
}
.intro__title {
  color: #ffffff;
  text-shadow: 3px 4px 2px #1d3515;
}
@media (min-width: 992px) {
  .intro__title--float {
    position: absolute;
    top: 0;
    left: 0;
  }
  .intro__title--float:nth-child(1) {
    top: 1.5rem;
  }
  .intro__title--float:nth-child(2) {
    top: 4rem;
  }
}
.intro__description {
  color: inherit;
  margin-top: 1rem;
}
.intro__subtitle {
  color: inherit;
  margin-top: 1rem;
  font-size: 160%;
}

.about {
  overflow: hidden;
}
.about .section-title__title {
  text-transform: uppercase;
  color: #354154;
  font-size: 2.5rem;
}
.about__image {
  position: relative;
}
@media (min-width: 1200px) {
  .about__image {
    margin-right: 2rem;
  }
}
.about__image__img {
  max-width: 100%;
  height: auto;
  width: 100%;
}
.about__image__icon {
  position: absolute;
  width: 10rem;
  height: 10rem;
  top: 50%;
  left: 50%;
  z-index: 1;
  transform: translate(-50%, -50%);
}
.about__description {
  max-width: 50rem;
  margin-top: 2rem;
}
.about__icons {
  display: flex;
  flex-wrap: wrap;
  margin: 2rem 0;
}
.about__icons__item {
  width: calc(100% / 3);
  padding-right: 1rem;
  text-align: center;
}
@media (max-width: 767px) {
  .about__icons__item {
    width: 100%;
    padding-right: 0;
    display: flex;
    align-items: center;
  }
}
.about__icons__icon {
  margin-bottom: 1rem;
}
@media (max-width: 767px) {
  .about__icons__icon {
    width: 5rem;
    height: 5rem;
    object-fit: contain;
    margin-right: 2rem;
  }
}
@media (max-width: 575px) {
  .about__icons__icon {
    width: 4rem;
    height: 4rem;
  }
}
.about__icons__description {
  font-weight: 700;
}
@media (max-width: 767px) {
  .about__icons__description {
    max-width: 100%;
  }
}

.howitworks {
  background-color: #f5f6fb;
  overflow: hidden;
}
.howitworks .section-title__title {
  color: #354154;
  max-width: 52rem;
}
.howitworks .section-title__title span {
  white-space: nowrap;
}
.howitworks__description {
  max-width: 50rem;
  margin-top: 2rem;
}
.howitworks__image {
  margin-bottom: -2rem;
}
@media (min-width: 1200px) {
  .howitworks__image {
    margin-top: -2rem;
  }
}
.howitworks__image__img {
  max-width: 100%;
  height: auto;
}
.howitworks__icons {
  display: flex;
  flex-wrap: wrap;
  margin: 2rem 0;
}
.howitworks__icons__item {
  width: calc(100% / 3);
  padding-right: 1rem;
}
@media (max-width: 767px) {
  .howitworks__icons__item {
    width: 100%;
    padding-right: 0;
    margin-bottom: 2rem;
  }
}
.howitworks__icons__icon {
  margin-bottom: 1rem;
}
@media (max-width: 767px) {
  .howitworks__icons__icon {
    width: 5rem;
    height: 5rem;
    object-fit: contain;
    margin-right: 2rem;
  }
}
@media (max-width: 575px) {
  .howitworks__icons__icon {
    width: 4rem;
    height: 4rem;
  }
}

.facts {
  background-color: #f5f6fb;
}
@media (max-width: 1199px) {
  .facts {
    overflow: hidden;
  }
}
.facts .section-title__title {
  color: #354154;
}
.facts__number {
  display: block;
  margin-bottom: 1rem;
  color: #8dcf3f;
}
.facts__image {
  text-align: center;
}
@media (min-width: 1200px) {
  .facts__image {
    margin-bottom: -10rem;
  }
}
.facts__image__img {
  max-width: 100%;
  height: auto;
}
.facts__icons {
  display: flex;
  flex-wrap: wrap;
  margin: 3rem 0 2rem 0;
}
.facts__icons__item {
  width: calc(100% / 3);
  padding-right: 1rem;
}
@media (max-width: 767px) {
  .facts__icons__item {
    width: 100%;
    padding-right: 0;
    margin-bottom: 2rem;
  }
}
.facts__icons__title {
  margin-bottom: 1rem;
}
@media (min-width: 768px) {
  .facts__icons__description {
    max-width: 22rem;
  }
}
.facts__icons__icon {
  margin-bottom: 1rem;
}
@media (max-width: 767px) {
  .facts__icons__icon {
    width: 5rem;
    height: 5rem;
    object-fit: contain;
    margin-right: 2rem;
  }
}
@media (max-width: 575px) {
  .facts__icons__icon {
    width: 4rem;
    height: 4rem;
  }
}

.why {
  overflow: hidden;
}
.why .section-title {
  text-align: center;
}
.why__wrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 3rem;
}
.why__item {
  margin: 1rem 0;
  width: 100%;
}
@media (min-width: 992px) {
  .why__item {
    width: calc(100% / 2);
  }
}
@media (min-width: 1199px) {
  .why__item {
    width: calc(100% / 3);
  }
}
.why__item__inner {
  height: 100%;
  background-color: rgba(245, 246, 251, 0.55);
  padding: 2rem;
}
@media (min-width: 768px) {
  .why__item__inner {
    padding: 3rem;
  }
}
@media (min-width: 992px) {
  .why__item__inner {
    margin: 0 1rem;
  }
}
.why__icon {
  margin-bottom: 1rem;
  width: 4.5rem;
  height: 4.5rem;
  object-fit: contain;
}
@media (max-width: 767px) {
  .why__icon {
    width: 5rem;
    height: 5rem;
  }
}
@media (max-width: 575px) {
  .why__icon {
    width: 4rem;
    height: 4rem;
  }
}
.why__title {
  margin-bottom: 1rem;
}

.reviews {
  overflow: hidden;
}
.reviews .section-title {
  text-align: center;
}
.reviews__description {
  max-width: 36rem;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.reviews__carousel {
  overflow: visible;
  margin-top: 3rem;
}
.reviews__item {
  background-color: #ffffff;
  padding: 2rem 1.5rem;
  margin: 1rem 0;
  box-shadow: 0px 0px 15px 3px rgba(0, 0, 0, 0.05);
}
.reviews__author {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}
.reviews__img {
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 8px;
  overflow: hidden;
  object-fit: cover;
  margin-right: 1rem;
}
.reviews__title {
  margin-bottom: 2rem;
}

.newsletter {
  background-color: #5d6e8a;
  color: #ffffff;
  overflow: hidden;
}
.newsletter__inner {
  position: relative;
  text-align: center;
}
.newsletter__img {
  position: absolute;
  bottom: 0;
  max-height: 95%;
  max-width: 20rem;
}
@media (max-width: 991px) {
  .newsletter__img {
    display: none;
  }
}
@media (min-width: 1500px) {
  .newsletter__img {
    max-width: 25rem;
  }
}
.newsletter__img--phone {
  left: -9rem;
}
@media (min-width: 1200px) {
  .newsletter__img--phone {
    left: -6rem;
  }
}
@media (min-width: 1500px) {
  .newsletter__img--phone {
    left: 0;
  }
}
.newsletter__img--guy {
  right: -9rem;
}
@media (min-width: 1200px) {
  .newsletter__img--guy {
    right: -6rem;
  }
}
@media (min-width: 1500px) {
  .newsletter__img--guy {
    right: 0;
  }
}
.newsletter__form {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 2rem 0;
}
.newsletter__input {
  max-width: 100%;
  width: 27rem;
}
.newsletter__input__wrap {
  max-width: 100%;
  position: relative;
  margin: 0.5rem;
}
.newsletter__input__wrap::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 1.3rem;
  width: 1.3rem;
  height: 1.3rem;
  transform: translateY(-50%);
  background: url("../img/newsletter/input-icon.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.newsletter__button__wrap {
  max-width: 100%;
  margin: 0.5rem;
}
@media (min-width: 992px) {
  .newsletter .section-title__description {
    padding: 0 24%;
  }
}
.newsletter .btn-own a {
  color: #fff;
}

input {
  background-color: #ffffff;
  padding: 1rem 3rem;
  border-radius: 5rem;
  border: 1px solid #ffffff;
  outline: none;
  font-size: 1.12rem;
  font-weight: 500;
  box-shadow: none;
  -webkit-appearance: none;
  transition: box-shadow 0.3s, border-color 0.3s;
}
input:focus {
  border-color: #8dcf3f;
  box-shadow: 0px 0px 15px 0px rgba(141, 207, 63, 0.25);
}

.form-home {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin: 2rem 0;
  width: 37rem;
  max-width: 100%;
}
@media (max-width: 991px) {
  .form-home {
    margin-left: auto;
    margin-right: auto;
  }
}
.form-home__form-wrapper {
  background: #5d6e8a;
  border-radius: 8px;
  padding: 1.5rem 1rem 1rem;
}
.form-home__input {
  width: 100%;
  padding-right: 8rem;
}
.form-home__input__wrap {
  width: 100%;
  position: relative;
}
.form-home__input__wrap::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 1.3rem;
  width: 1.3rem;
  height: 1.3rem;
  transform: translateY(-50%);
  background: url("../img/intro/input-icon.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.form-home__button__wrap {
  position: absolute;
  top: 0;
  right: 0;
}
.form-home__button__wrap .btn-own {
  padding: 1rem 2rem;
}

.form-page {
  position: relative;
  max-width: 100%;
  margin-top: 1.5rem;
}
@media (max-width: 991px) {
  .form-page {
    margin-left: auto;
    margin-right: auto;
  }
}
.form-page__wrapper {
  background: #5d6e8a;
  border-radius: 8px;
  padding: 2.5rem;
  margin-top: 1.5rem;
}
@media (max-width: 767px) {
  .form-page__wrapper {
    padding: 1.5rem;
  }
}
.form-page__input {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
@media (max-width: 767px) {
  .form-page__input {
    padding: 1rem 2rem;
  }
}
.form-page__input__wrap {
  width: 100%;
  position: relative;
}
.form-page__input--button {
  text-align: left;
  color: #757575;
}
.form-page__input--readonly {
  background-color: #b4bdcc;
}
.form-page__upload {
  position: relative;
}
.form-page__upload::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 1.3rem;
  width: 1.3rem;
  height: 1.3rem;
  transform: translateY(-50%);
  background: url("../../img/icons/upload.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  pointer-events: none;
}
.form-page__upload__file {
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: -1;
  pointer-events: none;
}
.form-page__button__wrap {
  width: 100%;
}
.form-page__button__wrap .btn-own {
  width: 100%;
  padding: 0.7rem 3rem;
  font-size: 1.4rem;
}
.form-page__button__wrap .btn-own img {
  position: absolute;
  top: 50%;
  right: 2rem;
  transform: translateY(-50%);
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

#up {
  position: fixed;
  right: 0;
  bottom: 0;
  height: 3rem;
  width: 3rem;
  text-align: center;
  margin: 1.5rem;
  border-radius: 2px;
  background-color: #8dcf3f;
  opacity: 0;
  transform: translate3d(0, 8rem, 0);
  transition: all 0.3s;
  cursor: pointer;
  z-index: 9;
}
#up::before {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../img/icons/arrow-up.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 1rem;
  pointer-events: none;
}
#up.active {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}
#up:hover {
  opacity: 1;
  background: #73af2c;
}

.offer {
  overflow: hidden;
}
.offer .section-title__title {
  color: #354154;
  text-align: center;
}
.offer .section-title__span {
  text-align: center;
}
@media (min-width: 992px) {
  .offer__icons {
    padding: 0 16.2rem;
  }
}
.offer__icons__description {
  margin-top: 1rem;
}
.offer__icons__item {
  margin-top: 3.2rem;
}
@media (max-width: 767px) {
  .offer__icons__item {
    text-align: center;
  }
}
.offer__icons__wrapper {
  margin-right: 3rem;
}
@media (max-width: 767px) {
  .offer__icons__wrapper {
    margin: 0 0 1rem;
  }
}
.offer__icons__wrapper img {
  height: 10rem;
  width: 12rem;
  object-fit: cover;
}
@media (max-width: 400px) {
  .offer__icons__wrapper img {
    max-width: 100%;
    width: 100%;
    height: auto;
  }
}

.kindofjunk {
  padding: 5rem 0 4.8rem;
  background-color: #f5f6fb;
  overflow: hidden;
}
.kindofjunk .section-title {
  margin-bottom: 3.3rem;
}
.kindofjunk .section-title__title {
  color: #354154;
  text-align: center;
}
.kindofjunk .section-title__span {
  text-align: center;
}
.kindofjunk__icons__item {
  padding: 3.2rem;
  margin-bottom: 1.5rem;
  border-radius: 0.5rem;
  background: #fff;
  transition: 0.3s;
}
.kindofjunk__icons__item:hover {
  box-shadow: 0 0.375rem 1.5rem rgba(0, 0, 0, 0.3);
}
.kindofjunk__icons__item:hover .kindofjunk__title {
  color: #8dcf3f;
}
@media (max-width: 767px) {
  .kindofjunk__icons__item {
    padding: 2rem;
    text-align: center;
  }
}
.kindofjunk__icons__item .kindofjunk__title {
  transition: 0.3s;
}
.kindofjunk__icons__icon {
  width: 14.375rem;
  height: 14.375rem;
  object-fit: cover;
}
@media (max-width: 400px) {
  .kindofjunk__icons__icon {
    max-width: 100%;
    width: 100%;
  }
}
.kindofjunk__img__wrapper {
  padding-right: 3rem;
}
@media (max-width: 767px) {
  .kindofjunk__img__wrapper {
    padding: 0;
  }
}
.kindofjunk__icons__description {
  margin-top: 1rem;
}
.kindofjunk__bottom-description {
  margin-top: 1.3rem;
  color: #8dcf3f;
  font-weight: 700;
  font-size: 1.5rem;
  text-align: center;
}
@media (max-width: 767px) {
  .kindofjunk__content {
    margin-top: 2rem;
  }
}

.fairprice {
  padding: 5rem 0 4.8rem;
  overflow: hidden;
}
.fairprice .section-title__title {
  color: #354154;
  text-align: center;
}
.fairprice .section-title__span {
  text-align: center;
}
.fairprice__description {
  padding: 0 14.1rem;
  text-align: center;
}
@media (max-width: 991px) {
  .fairprice__description {
    padding: 0;
  }
}
.fairprice__main {
  margin-top: 3rem;
}
.fairprice__item {
  height: 19.5rem;
  border-radius: 0.5rem;
  margin-top: 3.05rem;
  background: #f5f6fb;
  box-shadow: 0 0.375rem 1.5rem rgba(0, 0, 0, 0.1);
  text-align: center;
  cursor: pointer;
  transition: 0.3s;
}
@media (max-width: 767px) {
  .fairprice__item {
    margin-top: 2rem;
    height: 15rem;
  }
}
.fairprice__item:hover {
  box-shadow: 0 0.375rem 1.5rem rgba(0, 0, 0, 0.3);
}
.fairprice__title {
  padding: 0 1.2rem;
  color: #354154;
  font-size: 2rem;
}
.fairprice__button__wrap {
  margin-top: 3.2rem;
  text-align: center;
}

.intro-junk .intro__inner__bg, .intro-about-us .intro__inner__bg {
  bottom: -7.1%;
  left: 42.3%;
}
@media (max-width: 1499px) {
  .intro-junk .intro__inner__bg, .intro-about-us .intro__inner__bg {
    bottom: 5%;
    left: 42.3%;
  }
}
@media (max-width: 1499px) {
  .intro-junk .intro__inner__bg img, .intro-about-us .intro__inner__bg img {
    max-width: 35rem;
  }
}
.intro-junk .intro__inner, .intro-about-us .intro__inner {
  padding-top: 9rem;
  padding-bottom: 4rem;
}
.intro-junk .intro__inner__content, .intro-about-us .intro__inner__content {
  position: relative;
  z-index: 1;
  margin-top: 1.1rem;
}

.intro-about-us .intro__inner__bg {
  z-index: 0;
  bottom: -7.2%;
  left: 42.6%;
}

.activejunkers {
  overflow: hidden;
}
.activejunkers .section-title__title {
  color: #354154;
  text-align: center;
}
@media (max-width: 575px) {
  .activejunkers .section-title__title br {
    display: none;
  }
}
.activejunkers .section-title__span {
  text-align: center;
}
.activejunkers__item {
  margin-top: 2.3rem;
}
.activejunkers__item:hover .activejunkers__title {
  color: #8dcf3f;
}
.activejunkers__item .activejunkers__title {
  transition: 0.3s;
}
.activejunkers__icons__icon {
  width: 100%;
  border-radius: 0.5rem;
}
.activejunkers__title {
  margin-top: 1.5rem;
  text-align: center;
}

.howjunk {
  background-color: #f5f6fb;
  overflow: hidden;
}
.howjunk .section-title__title {
  color: #354154;
  text-align: center;
}
.howjunk .section-title__span {
  text-align: center;
}
.howjunk__item {
  margin-top: 2.3rem;
}
.howjunk__item:hover .howjunk__title {
  color: #8dcf3f;
}
.howjunk__item .howjunk__title {
  transition: 0.3s;
}
.howjunk__icons__icon {
  width: 100%;
  border-radius: 0.5rem;
}
.howjunk__title {
  margin-top: 1.5rem;
  text-align: center;
}
.howjunk__content {
  margin-top: 0.6rem;
  text-align: center;
  line-height: 1.9rem;
}

.tell__us__about {
  background: none;
}

.ourdrivers {
  background-color: #f5f6fb;
  overflow: hidden;
}
.ourdrivers .section-title {
  margin-bottom: 2.4rem;
}
@media (max-width: 767px) {
  .ourdrivers .section-title {
    margin-bottom: 1.5rem;
  }
}
.ourdrivers .section-title__title {
  color: #354154;
  text-align: center;
}
.ourdrivers .section-title__span {
  text-align: center;
}
.ourdrivers__type__item {
  margin: 1rem 0 0;
  padding: 1rem 2rem;
  border-radius: 5rem;
  background: #fff;
  font-weight: bold;
}
.ourdrivers__type_content {
  margin-right: 0.3rem;
}
.ourdrivers__content {
  color: #8dcf3f;
}
.ourdrivers__item {
  height: 19.5rem;
  border-radius: 0.5rem;
  margin-top: 3.05rem;
  background: #fff;
  box-shadow: 0 0.375rem 1.5rem rgba(0, 0, 0, 0.1);
  text-align: center;
  cursor: pointer;
  transition: 0.3s;
}
@media (max-width: 767px) {
  .ourdrivers__item {
    margin-top: 2rem;
    height: 15rem;
  }
}
.ourdrivers__item:hover:not(.active) {
  box-shadow: 0 0.375rem 1.5rem rgba(0, 0, 0, 0.3);
}
.ourdrivers__item:not(.active) .ourdrivers__icons__icon {
  display: none;
}
.ourdrivers__item:not(.active) .ourdrivers__description {
  margin: 0.7rem 0 0;
}
.ourdrivers__item.active {
  background: #354154;
}
.ourdrivers__item.active .ourdrivers__description {
  color: #fff;
}
.ourdrivers__item.active .ourdrivers__title {
  color: #fff;
}
.ourdrivers__icons__icon {
  width: 2.688rem;
  height: 2.688rem;
}
.ourdrivers__price {
  margin-top: -0.3rem;
  color: #8dcf3f;
  font-size: 3rem;
  font-weight: bold;
  font-family: "Jellee Roman", sans-serif;
}
@media (max-width: 991px) {
  .ourdrivers__price {
    font-size: 2rem;
  }
}
@media (max-width: 767px) {
  .ourdrivers__price {
    font-size: 1.5rem;
  }
}
.ourdrivers__title {
  margin: 0.7rem 0 1.6rem 1.3rem;
}
.ourdrivers__button__wrap {
  margin-top: 3.2rem;
  text-align: center;
}

.orderform {
  padding: 3.7rem 0 6.3rem;
  overflow: hidden;
}
.orderform .section-title {
  margin-bottom: 3.4rem;
}
@media (max-width: 767px) {
  .orderform .section-title {
    margin-bottom: 1.5rem;
  }
}
.orderform .section-title__title {
  color: #354154;
  text-align: center;
}
.orderform .section-title__span {
  text-align: center;
}
.orderform .form-page {
  margin-top: 3.2rem;
}
.orderform .form-page__form__order, .orderform .form-page__order__detail {
  width: 32%;
}
@media (max-width: 991px) {
  .orderform .form-page__form__order, .orderform .form-page__order__detail {
    width: 45%;
  }
}
@media (max-width: 767px) {
  .orderform .form-page__form__order, .orderform .form-page__order__detail {
    width: 100%;
  }
}
.orderform .form-page__form__order {
  margin-top: 1.5rem;
  margin-right: 8rem;
}
@media (max-width: 767px) {
  .orderform .form-page__form__order {
    margin-right: 0;
  }
}
.orderform .form-page__form__order .form-page__title {
  margin: 0 0 1.4rem 1.8rem;
}
.orderform .form-page__input {
  padding: 1rem 2rem;
  border: 0.063rem solid #354154;
}
.orderform .form-page__input::placeholder {
  color: #080807;
  font-weight: bold;
}
.orderform .form-page__form__item {
  margin-bottom: 0.938rem;
}
.orderform .form-page .checkbox {
  position: relative;
  display: block;
  width: 100%;
  margin-top: 1.7rem;
}
.orderform .form-page .checkbox .checkbox-label {
  position: relative;
  display: block;
  padding-left: 2.75rem;
  margin-bottom: 0;
  font-size: 1.12rem;
  font-weight: bold;
  cursor: pointer;
}
.orderform .form-page .checkbox .checkbox-label:before {
  content: "";
  position: absolute;
  left: 0;
  top: -0.15rem;
  height: 1.75rem;
  width: 1.75rem;
  border-radius: 0.3rem;
  border: 0.063rem solid #354154;
  transition: transform 0.3s ease;
}
.orderform .form-page .checkbox .checkbox-label:after {
  content: "";
  position: absolute;
  top: 0.4rem;
  left: 0.563rem;
  display: block;
  width: 0.75rem;
  height: 0.438rem;
  border-bottom: 0.188rem solid #8dcf3f;
  border-left: 0.188rem solid #8dcf3f;
  transform: rotate(-45deg) scale(0);
  transition: transform ease 0.3s;
}
.orderform .form-page .checkbox input[type=checkbox] {
  position: absolute;
  left: 0;
  opacity: 0;
}
.orderform .form-page .checkbox input[type=checkbox]:checked ~ label:before {
  border: 0.063rem solid #8dcf3f;
}
.orderform .form-page .checkbox input[type=checkbox]:checked ~ label:after {
  transform: rotate(-45deg) scale(1);
}
.orderform .form-page .checkbox input[type=checkbox]:focus + label::before {
  outline: 0;
}
.orderform .form-page__main__content {
  margin-bottom: 1.2rem;
}
.orderform .form-page__type__content {
  margin-bottom: 0.15rem;
}
.orderform .form-page__pick__up, .orderform .form-page__summary {
  margin-top: 2.7rem;
}
.orderform .form-page__pick__up {
  color: #8dcf3f;
}
.orderform .form-page__price {
  margin-top: 0.1rem;
  color: #8dcf3f;
  font-size: 3rem;
  font-weight: bold;
  font-family: "Jellee Roman", sans-serif;
}
@media (max-width: 991px) {
  .orderform .form-page__price {
    font-size: 2rem;
  }
}
@media (max-width: 767px) {
  .orderform .form-page__price {
    font-size: 1.5rem;
  }
}
.orderform .form-page__order__detail {
  padding: 3rem 3rem 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 0.375rem 1.5rem rgba(0, 0, 0, 0.1);
}
@media (max-width: 767px) {
  .orderform .form-page__order__detail {
    padding: 3rem 2rem 2rem;
    margin-top: 3rem;
  }
}
.orderform .form-page__order__detail .form-page__title {
  margin: 0 0 1.9rem;
}
.orderform__button__wrap {
  margin-top: 2.75rem;
  text-align: center;
}
.orderform__button__wrap .btn-own {
  width: 100%;
}

.whoweare {
  overflow: hidden;
}
.whoweare .section-title__title {
  color: #354154;
  text-align: center;
}
.whoweare .section-title__span {
  text-align: center;
}
.whoweare__main__content {
  padding: 0 15.8rem;
  margin-top: 1.5rem;
  line-height: 1.9rem;
  text-align: center;
}
@media (max-width: 1199px) {
  .whoweare__main__content {
    padding: 0;
  }
}
@media (max-width: 767px) {
  .whoweare__main__content {
    text-align: center;
  }
}
.whoweare__main {
  margin-top: 0.6rem;
}
.whoweare__item {
  width: 19.375rem;
  margin-top: 2.3rem;
  text-align: center;
}
@media (max-width: 767px) {
  .whoweare__item {
    width: 15rem;
    margin: 3rem auto 0;
  }
}
.whoweare__item:hover .whoweare__title {
  color: #8dcf3f;
}
.whoweare__item .whoweare__title {
  transition: 0.3s;
}
.whoweare__icons__icon {
  width: 100%;
}
.whoweare__title {
  margin-top: 1.5rem;
  text-align: center;
}
.whoweare__content {
  margin-top: 0.6rem;
  text-align: center;
  line-height: 1.9rem;
}

.ourgoal {
  overflow: hidden;
  background-color: #f5f6fb;
}
.ourgoal .section-title {
  margin-bottom: 2.3rem;
}
.ourgoal .section-title__title {
  color: #354154;
  text-align: center;
}
.ourgoal .section-title__span {
  text-align: center;
}
.ourgoal__main__content {
  line-height: 1.9rem;
}
@media (max-width: 991px) {
  .ourgoal__main__content {
    margin-top: 2.5rem;
    text-align: center;
  }
}
.ourgoal__item {
  padding-right: 3rem;
  text-align: center;
}
@media (max-width: 991px) {
  .ourgoal__item {
    width: 80%;
    padding: 0;
    margin: 0 auto;
  }
}
@media (max-width: 767px) {
  .ourgoal__item {
    width: auto;
  }
}
.ourgoal__icons__icon {
  width: 100%;
}

.chooseus {
  overflow: hidden;
  padding-bottom: 9.6rem;
}
.chooseus .section-title__title {
  color: #354154;
  text-align: center;
}
.chooseus .section-title__span {
  text-align: center;
}
.chooseus__main__content {
  padding: 0 15.8rem;
  margin-top: 1.5rem;
  line-height: 1.9rem;
  text-align: center;
}
@media (max-width: 1199px) {
  .chooseus__main__content {
    padding: 0;
  }
}
.chooseus__main {
  margin-top: 2rem;
}
.chooseus__item {
  margin-top: 2.3rem;
  padding: 0 1rem;
  text-align: center;
}
@media (max-width: 991px) {
  .chooseus__item {
    width: 50%;
  }
}
@media (max-width: 767px) {
  .chooseus__item {
    width: 100%;
  }
}
.chooseus__item:hover .chooseus__title {
  color: #8dcf3f;
}
.chooseus__item .chooseus__title {
  transition: 0.3s;
}
.chooseus__icons__icon {
  width: 5.425rem;
  height: 5.425rem;
}
@media (max-width: 575px) {
  .chooseus__icons__icon {
    width: 3.425rem;
    height: 3.425rem;
  }
}
.chooseus__title {
  margin-top: 1.5rem;
  text-align: center;
}
.chooseus__content {
  margin-top: 0.6rem;
  text-align: center;
  line-height: 1.9rem;
}

.questions {
  overflow: hidden;
}
.questions .section-title__title {
  color: #354154;
  text-align: center;
}
.questions .section-title__span {
  text-align: center;
}
.questions__accordion {
  margin-top: 3.4rem;
}
.questions__accordion__item {
  width: 66%;
  padding: 0 1.5rem;
  margin: 0.625rem auto 0;
  border-radius: 0.625rem;
  background: #fcfdfe;
  box-shadow: 0 0.188rem 0.375rem rgba(0, 0, 0, 0.16);
  transition: 0.3s;
}
@media (max-width: 1199px) {
  .questions__accordion__item {
    width: 100%;
  }
}
.questions__accordion__item.open {
  padding-bottom: 1rem;
}
.questions__accordion__title {
  position: relative;
  border-radius: 0.625rem;
  padding: 1rem 2.3rem 1rem 0;
  background: #fcfdfe;
  cursor: pointer;
  transition: 0.2s;
}
.questions__accordion__title:hover {
  color: #8dcf3f;
}
.questions__accordion__title.active {
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 0.063rem solid rgba(0, 0, 0, 0.05);
  border-radius: 0;
  color: #8dcf3f;
}
.questions__accordion__title.active .questions__accordion__span {
  transform: translateY(2px) rotate(-180deg);
}
@media (max-width: 575px) {
  .questions__accordion__title.active .questions__accordion__span {
    transform: rotate(-180deg);
  }
}
.questions__accordion__panel {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
}
.questions__accordion__span {
  position: absolute;
  right: 0;
  top: 0.9rem;
}

.info {
  padding: 5rem 0 2.5rem;
}
.info__main {
  width: 1100px;
  margin: 0 auto;
}
@media (max-width: 1199px) {
  .info__main {
    width: unset;
  }
}
.info__item {
  margin: 3rem 0;
}
.info__item:hover .activejunkers__title {
  color: #8dcf3f;
}
.info__icons__icon {
  width: 100%;
}
.info__description {
  margin-bottom: 2.3rem;
}
.info__description a {
  color: #8dcf3f;
}

.intro-info {
  position: relative;
  display: flex;
  align-items: center;
  height: 34.375rem;
  width: 100%;
  background-image: url(../img/info/house.jpg);
  background-position: top, center;
  background-repeat: no-repeat;
  background-size: cover;
}
@media (max-width: 767px) {
  .intro-info {
    height: 25rem;
  }
}
.intro-info:before {
  z-index: 0;
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgba(53, 65, 84, 0.76);
}
.intro-info .intro__title {
  position: relative;
  margin-top: 9.5rem;
  padding: 0 9rem;
  text-align: center;
}
@media (max-width: 991px) {
  .intro-info .intro__title {
    padding: 0;
  }
}
@media (max-width: 767px) {
  .intro-info .intro__title {
    margin-top: 3.5rem;
  }
}

.junk-generally {
  overflow: hidden;
}
.junk-generally .section-title {
  margin-bottom: 2.3rem;
}
@media (max-width: 767px) {
  .junk-generally .section-title {
    margin-bottom: 1.5rem;
  }
}
.junk-generally .section-title__title {
  color: #354154;
  text-align: center;
}
.junk-generally .section-title__span {
  text-align: center;
}
.junk-generally__main__content {
  line-height: 1.9rem;
}
@media (max-width: 991px) {
  .junk-generally__main__content {
    margin-top: 2.5rem;
    text-align: center;
  }
}
.junk-generally__item {
  padding-right: 3rem;
  text-align: center;
}
@media (max-width: 991px) {
  .junk-generally__item {
    width: 80%;
    padding: 0;
    margin: 0 auto;
  }
}
@media (max-width: 767px) {
  .junk-generally__item {
    width: auto;
  }
}
.junk-generally__icons__icon {
  width: 100%;
}

.save-money {
  overflow: hidden;
}
.save-money .section-title {
  margin-bottom: 2.3rem;
}
@media (max-width: 767px) {
  .save-money .section-title {
    margin-bottom: 1.5rem;
  }
}
.save-money .section-title__title {
  color: #354154;
  text-align: center;
}
.save-money .section-title__span {
  text-align: center;
}
.save-money__main__content {
  line-height: 1.9rem;
}
@media (max-width: 991px) {
  .save-money__main__content {
    margin-bottom: 2.5rem;
    text-align: center;
  }
}
.save-money__item {
  padding-left: 3rem;
  text-align: center;
}
@media (max-width: 991px) {
  .save-money__item {
    width: 80%;
    padding: 0;
    margin: 0 auto;
  }
}
@media (max-width: 767px) {
  .save-money__item {
    width: auto;
  }
}
.save-money__icons__icon {
  width: 100%;
}

.custom-quote {
  background-color: #f5f6fb;
  overflow: hidden;
}
.custom-quote .section-title {
  margin-bottom: 2.3rem;
}
@media (max-width: 767px) {
  .custom-quote .section-title {
    margin-bottom: 1.5rem;
  }
}
.custom-quote .section-title__title {
  color: #354154;
  text-align: center;
}
.custom-quote .section-title__span {
  text-align: center;
}
.custom-quote__content__col {
  padding-right: 8.6rem;
}
@media (max-width: 1499px) {
  .custom-quote__content__col {
    padding: 0 15px;
  }
}
.custom-quote__main__content {
  line-height: 1.9rem;
  text-align: center;
}
@media (max-width: 991px) {
  .custom-quote__main__content {
    margin-top: 2.5rem;
  }
}
.custom-quote__main__content.second {
  margin-top: 0.9rem;
}
.custom-quote__item {
  width: 67%;
  margin: 0 auto;
  text-align: center;
}
@media (max-width: 767px) {
  .custom-quote__item {
    width: 60%;
  }
}
@media (max-width: 575px) {
  .custom-quote__item {
    width: auto;
  }
}
.custom-quote__icons__icon {
  width: 100%;
}
.custom-quote .applinks {
  justify-content: center;
  margin-top: 2.8rem;
}
.custom-quote__order {
  margin-top: 1.2rem;
  text-align: center;
}
.custom-quote__order .btn-own {
  min-width: 25.15rem;
}
@media (max-width: 1199px) {
  .custom-quote__order .btn-own {
    min-width: unset;
  }
}

.service-areas {
  overflow: hidden;
  background-color: #f5f6fb;
}
.service-areas .section-title {
  margin-bottom: 2.3rem;
}
.service-areas .section-title__title {
  color: #354154;
  text-align: center;
}
.service-areas .section-title__span {
  text-align: center;
}
.service-areas__main__content {
  line-height: 1.9rem;
}
@media (max-width: 991px) {
  .service-areas__main__content {
    margin-top: 2.5rem;
    text-align: center;
  }
}
.service-areas__item {
  padding-right: 3rem;
  text-align: center;
}
@media (max-width: 991px) {
  .service-areas__item {
    width: 80%;
    padding: 0;
    margin: 0 auto;
  }
}
@media (max-width: 767px) {
  .service-areas__item {
    width: auto;
  }
}
.service-areas__icons__icon {
  width: 100%;
}
.service-areas .btn-own {
  pointer-events: none;
}

.local-junk {
  overflow: hidden;
}
.local-junk .section-title__title {
  color: #354154;
  text-align: center;
}
.local-junk .section-title__span {
  text-align: center;
}
.local-junk__main__content {
  padding: 0 15.8rem;
  margin-top: 1.5rem;
  line-height: 1.9rem;
  text-align: center;
}
@media (max-width: 1199px) {
  .local-junk__main__content {
    padding: 0;
  }
}
.local-junk__list {
  width: 70%;
  margin: 4rem auto 0;
  padding: 0;
  list-style: none;
  column-count: 3;
}
@media (max-width: 991px) {
  .local-junk__list {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .local-junk__list {
    column-count: 2;
    font-size: 1rem;
  }
}
.local-junk__list__item {
  position: relative;
  padding-left: 25%;
  line-height: 1.9rem;
}
@media (max-width: 575px) {
  .local-junk__list__item {
    padding-left: 0;
  }
}
.local-junk__list__item:before {
  position: relative;
  content: "●";
  padding-right: 0.8rem;
  color: #8dcf3f;
}
@media (max-width: 767px) {
  .local-junk__list__item:before {
    padding-right: 0.4rem;
  }
}
.local-junk__form .form-page__title {
  text-align: center;
}
.local-junk__form .form-page__input {
  padding: 1rem 2rem;
  border: 0.063rem solid #354154;
  text-align: center;
}
.local-junk__form .form-page__input::placeholder {
  color: #080807;
  font-weight: bold;
}
.local-junk__form #result, .local-junk__form #result-submit {
  font-weight: bold;
  text-align: center;
}
.local-junk__form .available {
  color: #354154;
}
.local-junk__form .not-available {
  color: #f7b717;
}
.local-junk__buttons {
  display: none;
}
.local-junk__buttons.show {
  display: block;
}
.local-junk__email {
  display: none;
}
.local-junk__email.show {
  display: block;
}

.hire-junk {
  background-color: #f5f6fb;
  overflow: hidden;
}
.hire-junk .section-title {
  margin-bottom: 2.3rem;
}
@media (max-width: 767px) {
  .hire-junk .section-title {
    margin-bottom: 1.5rem;
  }
}
.hire-junk .section-title__title {
  color: #354154;
  text-align: center;
}
.hire-junk .section-title__span {
  text-align: center;
}
.hire-junk__content__col {
  padding-right: 8.6rem;
}
@media (max-width: 1499px) {
  .hire-junk__content__col {
    padding: 0 15px;
  }
}
.hire-junk__main__content {
  line-height: 1.9rem;
  text-align: center;
}
@media (max-width: 991px) {
  .hire-junk__main__content {
    margin-top: 2.5rem;
  }
}
.hire-junk__main__content.second {
  margin-top: 0.9rem;
}
.hire-junk__item {
  width: 55%;
  margin: 0 auto;
  text-align: center;
}
@media (max-width: 767px) {
  .hire-junk__item {
    width: 60%;
  }
}
@media (max-width: 575px) {
  .hire-junk__item {
    width: auto;
  }
}
.hire-junk__icons__icon {
  width: 100%;
}

#cookie-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #8dcf3f;
  color: #ffffff;
  font-size: 1rem;
  padding: 1rem 2rem;
  z-index: 10;
  box-shadow: 0 0 20px -10px rgba(0, 0, 0, 0.3);
  transition: transform 0.5s ease-out;
}
@media (max-width: 767px) {
  #cookie-bar {
    flex-direction: column;
    text-align: center;
  }
}
#cookie-bar.active {
  transform: translateY(100%);
}
#cookie-bar .more-info {
  color: #ffffff !important;
}
#cookie-bar .cookie-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
}
@media (max-width: 767px) {
  #cookie-bar .cookie-nav {
    margin-top: 1.5rem;
  }
}
#cookie-bar .cookie-btn {
  display: inline-block;
  position: relative;
  color: #8dcf3f;
  text-align: center;
  font-weight: 600;
  text-transform: uppercase;
  vertical-align: middle;
  cursor: pointer;
  border: none;
  box-shadow: none;
  background: #ffffff;
  padding: 0.4rem 1.4rem;
  margin: 0 1.5rem;
  font-size: 1rem;
}
#cookie-bar .cookie-btn:focus {
  box-shadow: none;
  outline: 0;
}

/*Alert*/
.alertbox {
  width: 34rem;
  max-width: calc(100% - 3.5rem);
  height: auto;
  max-height: 200px;
  min-height: 50px;
  position: fixed;
  bottom: 1.75rem;
  right: 1.75rem;
  margin-bottom: 0;
  z-index: 13;
  font-weight: 400;
  font-size: 1rem;
  box-shadow: 1px 7px 14px -5px rgba(0, 0, 0, 0.2);
  background: #ffffff;
  color: #000000;
  border-radius: 4px;
  border-left: 4px solid transparent;
  overflow: hidden;
  padding: 1.4rem 3.5rem 1.4rem 4.5rem;
  transition: all 1s;
  opacity: 0;
  transform: translateY(200%);
  animation: alertbox-in 6s 1 forwards;
}
@media (max-width: 1199px) {
  .alertbox {
    font-size: 1.4rem;
    width: 40rem;
    padding-left: 5.5rem;
  }
}
.alertbox strong {
  display: block;
}
.alertbox p {
  font-size: 1rem;
  margin: 0;
  color: #4d4d4d;
}
.alertbox.hide {
  animation: none;
  opacity: 0;
  transform: translateY(200%);
}

.alertbox-icon-wrap {
  position: absolute;
  top: 50%;
  left: 1.3rem;
  transform: translateY(-50%);
  width: 2rem;
  height: 2rem;
  padding: 7px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 1199px) {
  .alertbox-icon-wrap {
    width: 3rem;
    height: 3rem;
  }
}

.alertbox-icon {
  width: 1.5rem;
  height: 1.5rem;
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
}
@media (max-width: 1199px) {
  .alertbox-icon {
    width: 2rem;
    height: 2rem;
  }
}

.alertbox-close {
  position: absolute;
  right: 1rem;
  top: 50%;
  width: 1.5rem;
  cursor: pointer;
  height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(-50%);
  background-image: url("../../img/alertbox/close.svg");
  background-size: 70%;
  background-position: center;
  background-repeat: no-repeat;
}

.alertbox.alertbox-danger {
  border-color: #f44336;
}
.alertbox.alertbox-danger .alertbox-icon-wrap {
  background: #f44336;
}
.alertbox.alertbox-danger .alertbox-icon {
  background-image: url("../../img/alertbox/danger.svg");
}

.alertbox.alertbox-success {
  border-color: #2BDE3F;
}
.alertbox.alertbox-success .alertbox-icon-wrap {
  background: #2BDE3F;
}
.alertbox.alertbox-success .alertbox-icon {
  background-image: url("../../img/alertbox/success.svg");
}

.alertbox.alertbox-info {
  border-color: #1D72F3;
}
.alertbox.alertbox-info .alertbox-icon-wrap {
  background: #1D72F3;
}
.alertbox.alertbox-info .alertbox-icon {
  background-image: url("../../img/alertbox/info.svg");
}

@keyframes alertbox-in {
  0% {
    opacity: 0;
    transform: translateY(200%);
  }
  20%, 50%, 80% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(200%);
  }
}
/*Document page*/
#document {
  margin: 9rem 0 5rem 0;
}
@media (max-width: 991px) {
  #document {
    margin: 6rem 0 2rem 0;
  }
}
#document h1 {
  margin-bottom: 4rem;
}
#document h3 {
  margin-top: 3rem;
}
#document h3:first-child {
  margin-top: 0;
}
#document .first-level {
  padding-left: 30px;
}
#document .second-level {
  padding-left: 60px;
}
#document a {
  word-wrap: break-word;
}
#document .highlighted {
  font-style: italic;
  font-size: 1.5rem;
}

.error-page {
  margin: 13rem auto;
  max-width: 70rem;
  position: relative;
  padding: 0 1rem;
  color: #000000;
}
.error-page h1 {
  font-size: 6rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: inherit;
}
.error-page h2 {
  font-size: 3rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  color: inherit;
}
.error-page img {
  max-width: 100%;
  height: auto;
  width: 20rem;
}
@media (max-width: 991px) {
  .error-page {
    margin: 4rem auto;
  }
  .error-page img {
    width: 15rem;
  }
}
.error-page .link-home {
  display: inline-block;
  padding: 0.7rem 1.5rem;
  font-size: 1.5rem;
  background: #ffffff;
  color: #000000;
  border: 1px solid #000000;
  margin-top: 1rem;
  transition: all 0.5s;
}
.error-page .link-home:hover {
  background: #000000;
  color: #ffffff;
}
.error-page .navbar-brand {
  position: relative;
  bottom: 0.4rem;
  left: 3rem;
  min-height: 5.5rem;
  display: flex;
  align-items: center;
  color: #080807 !important;
  font-family: "Montserrat", sans-serif;
  font-size: 5rem;
  transition: hover 0.5s;
  letter-spacing: 0.1rem;
}
.error-page .navbar-brand:hover {
  color: black;
}

#app .fade-enter-active,
#app .fade-leave-active {
  transition: opacity 0.5s ease;
}
#app .fade-enter-from,
#app .fade-leave-to {
  opacity: 0;
}
#app .box {
  max-width: 60rem;
  margin: 0 auto;
  padding: 3rem 0;
}
#app .title {
  margin-bottom: 1rem;
  text-align: center;
}
#app .description {
  text-align: center;
}
#app .box-item {
  width: 100%;
  height: 100%;
}
#app .box-item--date {
  position: relative;
}
#app .box-item--date::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 1.1rem;
  width: 2.5rem;
  height: 2.5rem;
  background-image: url("../img/icons/calendar.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  transform: translateY(-50%);
  pointer-events: none;
}
#app .box-item--date input {
  padding-left: 4.3rem;
}
#app .box-item--address {
  position: relative;
}
#app .box-item--address::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 1.1rem;
  width: 2.5rem;
  height: 2.5rem;
  background-image: url("../img/icons/address.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  transform: translateY(-50%);
  pointer-events: none;
}
#app .box-item--address input {
  padding-left: 4.3rem;
}
#app .box-button {
  text-align: center;
  margin-top: 2rem;
}
#app .box-label {
  position: relative;
  width: 100%;
  height: 100%;
  margin-bottom: 0;
  background: #ffffff;
  color: #324155;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  border: 1px solid transparent;
  font-weight: 600;
  padding: 2.5rem 1rem;
  cursor: pointer;
  transition: color 0.3s, border-color 0.3s;
}
#app .box-label:hover {
  border-color: #8dcf3f;
  color: #8dcf3f;
}
#app .box-label:hover small {
  color: #99a0aa;
}
#app .box-label:hover strong {
  color: #324155;
}
#app .box-label small {
  display: block;
  font-weight: 400;
  font-size: 80%;
  color: #99a0aa;
  margin-top: 0.5rem;
}
#app .box-label small strong {
  font-weight: 600;
  display: block;
  color: #324155;
}
#app .box-label strong.simple {
  margin-top: auto;
  padding-top: 0.5rem;
  color: #324155;
}
#app .box-label--w-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
#app .box-label__icon {
  width: 3.5rem;
  height: 3.5rem;
  object-fit: contain;
  margin-bottom: 0.5rem;
}
#app .box-label::before {
  content: "";
  position: absolute;
  top: 1rem;
  left: 50%;
  transform: translateX(-50%);
  width: 1rem;
  height: 1rem;
  background-image: url("../img/icons/check.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  opacity: 0;
  transition: opacity 0.3s;
}
#app .box-radio {
  display: none;
}
#app .box-radio:checked ~ .box-label {
  border-color: #8dcf3f;
  color: #8dcf3f;
}
#app .box-radio:checked ~ .box-label small {
  color: #99a0aa;
}
#app .box-radio:checked ~ .box-label strong {
  color: #324155;
}
#app .box-radio:checked ~ .box-label::before {
  opacity: 1;
}
#app .box-textarea {
  width: 100%;
  height: 100%;
  margin-bottom: 0;
  background: #ffffff;
  color: #324155;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  border: 1px solid transparent;
  padding: 1.3rem 1.7rem;
  font-size: 1.2rem;
  transition: box-shadow 0.3s;
}
#app .box-textarea::placeholder {
  color: #324155;
  font-weight: 500;
  font-size: 1rem;
}
#app .box-textarea:focus {
  border: 1px solid #8dcf3f;
  outline: none;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
}
#app .box-input {
  width: 100%;
  margin-bottom: 0;
  background: #ffffff;
  color: #324155;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  padding: 1.3rem 1.7rem;
  font-size: 1.2rem;
  transition: box-shadow 0.3s;
}
#app .box-input::placeholder {
  color: #324155;
  font-weight: 500;
  font-size: 1rem;
}
#app .box-input:focus {
  outline: none;
  box-shadow: 0px 4px 20px rgba(141, 207, 63, 0.5);
}
#app .box-input.invalid {
  box-shadow: 0px 4px 20px rgba(255, 0, 0, 0.5);
}
@media (max-width: 767px) {
  #app .box-input {
    width: 100%;
  }
}
#app .box-input--full {
  width: 100%;
}
#app .box-summary {
  width: 100%;
  margin-bottom: 1rem;
  background: #ffffff;
  color: #324155;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  padding: 1.3rem 1.7rem;
}
#app .box-summary__title {
  font-size: 0.8rem;
  font-weight: 600;
  display: block;
}
#app .box-summary__value {
  font-size: 1rem;
  font-weight: 500;
  display: block;
}
#app .box-summary__text {
  font-size: 0.9rem;
}
#app .box-summary--column {
  column-count: 3;
}
@media (max-width: 767px) {
  #app .box-summary--column {
    column-count: 2;
  }
}
@media (max-width: 575px) {
  #app .box-summary--column {
    column-count: 1;
  }
}
#app .box-summary--info {
  background-color: #354154;
  color: #fff;
}
#app .button {
  display: block;
  width: 100%;
  margin: 0 auto;
  padding: 0.8rem 2rem;
  border-radius: 10px;
  border: 1px solid #8dcf3f;
  outline: none;
  font-size: 1.2rem;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
  background-color: #8dcf3f;
  color: #ffffff;
  transition: all 0.3s;
  z-index: 5;
}
#app .button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
#app .button:hover {
  background-color: #73af2c;
  border-color: #73af2c;
  color: #ffffff;
  box-shadow: 0px 8px 10px 0px rgba(141, 207, 63, 0.25);
}

.flatpickr-input {
  -webkit-appearance: none;
}

.flatpickr-monthDropdown-months {
  appearance: none !important;
  background-color: #fff !important;
  border: none !important;
  border-radius: 0 !important;
  padding: 0 !important;
  line-height: 1.4 !important;
  font-size: 0.9em !important;
}