#up {
    position: fixed;
    right: 0;
    bottom: 0;
    height: 3rem;
    width: 3rem;
    text-align: center;
    margin: 1.5rem;
    border-radius: 2px;
    background-color: $primary-color;
    opacity: 0;
    transform: translate3d(0, 8rem, 0);
    transition: all .3s;
    cursor: pointer;
    z-index: 9;

    &::before {
        content: " ";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('../../img/icons/arrow-up.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 1rem;
        pointer-events: none;
    }

    &.active {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }

    &:hover {
        opacity: 1;
        background: darken($primary-color, 10%);
    }
}
