.whoweare {
  overflow: hidden;
  .section-title {
    &__title {
      color: $secondary-color;
      text-align: center;
    }
  }
  .section-title__span {
    text-align: center;
  }
  &__main__content {
    padding: 0 15.8rem;
    margin-top: 1.5rem;
    line-height: 1.9rem;
    text-align:center;
    @media (max-width: 1199px) {
      padding: 0;
    }
    @media (max-width: 767px) {
      text-align: center;
    }
  }
  &__main {
    margin-top: 0.6rem;
  }
  &__item {
    width: 19.375rem;
    margin-top: 2.3rem;
    text-align: center;
    @media (max-width: 767px) {
      width: 15rem;
      margin: 3rem auto 0;
    }
    &:hover {
      .whoweare__title {
        color: $primary-color;
      }
    }
    .whoweare__title {
      transition: $transition-time;
    }
  }
  &__icons__icon {
    width: 100%;
  }
  &__title {
    margin-top: 1.5rem;
    text-align: center;
  }
  &__content {
    margin-top: 0.6rem;
    text-align: center;
    line-height: 1.9rem;
  }
}