.kindofjunk {
  padding: 5rem 0 4.8rem;
  background-color: $base-color-darken;
  overflow: hidden;
  .section-title {
    margin-bottom: 3.3rem;

    &__title {
      color: $secondary-color;
      text-align: center;
    }
  }
  .section-title__span {
    text-align: center;
  }
  &__icons__item {
    padding: 3.2rem;
    margin-bottom: 1.5rem;
    border-radius: 0.5rem;
    background: #fff;
    transition: $transition-time;
    &:hover{
      box-shadow: 0 0.375rem 1.5rem rgba(0, 0, 0, 0.3);
      .kindofjunk__title {
        color: $primary-color;
      }
    }
    @media (max-width: 767px) {
      padding: 2rem;
      text-align: center;
    }
    .kindofjunk__title {
      transition: $transition-time;
    }
  }
  &__icons__icon {
    width: 14.375rem;
    height: 14.375rem;
    object-fit: cover;
    @media (max-width: 400px) {
      max-width: 100%;
      width: 100%;
    }
  }
  &__img__wrapper {
    padding-right: 3rem;
    @media (max-width: 767px) {
      padding: 0;
    }
  }
  &__icons__description {
    margin-top: 1rem;
  }
  &__bottom-description {
    margin-top: 1.3rem;
    color: $primary-color;
    font-weight: 700;
    font-size: 1.5rem;
    text-align: center;
  }
  &__content {
    @media (max-width: 767px) {
      margin-top: 2rem;
    }
  }
}